/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Notification.styled';
import { notificationStore } from './';

const defaultContentFontSize = '13px';
const defaultPadding = '15px 0 0 0';

/**
 * Notification component
 * @param props {object} - The notification parameters, see [Carbon component API](https://react.carbondesignsystem.com/?path=/docs/components-notifications-toast--overview#component-api)
 * @returns {JSX.Element} - The notification component
 * @constructor
 */
function Notification(props) {
  const [transitionClass, setTransitionClass] = useState('entering');

  useEffect(() => {
    setTimeout(() => {
      setTransitionClass('entered');
    }, Styled.transitionDuration);
  }, []);

  useEffect(() => {
    if (props.isClosing) {
      setTransitionClass('exiting');
    }
  }, [props.isClosing]);

  let content = props.content || '';

  const onClose = () => {
    // NOTE: Because of https://github.com/carbon-design-system/carbon/issues/15699 this will not work as expected.
    setTransitionClass('exiting');

    const exitTimeout = setTimeout(() => {
      setTransitionClass('exited');

      if (typeof props.onClose === 'function') {
        props.onClose();
      }

      notificationStore.disposeNotification(props.id, props.kind, false);

      clearTimeout(exitTimeout);
    }, Styled.transitionDuration);
  };

  const onMouseEnter = () => {
    if (typeof props.onMouseEnter === 'function') {
      props.onMouseEnter();
    }
    notificationStore.setActiveNotification(props.id);
  };

  const onMouseLeave = () => {
    if (typeof props.onMouseLeave === 'function') {
      props.onMouseLeave();
    }
    notificationStore.unsetActiveNotification();
  };

  return (
    <Styled.ToastNotification
      className={transitionClass}
      statusIconDescription="Notification"
      aria-label="Close notification"
      lowContrast={false}
      {...getToastNotificationProps(props)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClose={onClose}
    >
      <div
        className={props.options?.contentClassName}
        style={props.options?.contentClassName ? {} : { fontSize: defaultContentFontSize, padding: defaultPadding }}
      >
        {content}
      </div>
    </Styled.ToastNotification>
  );
}

/**
 * Get the props for the ToastNotification component, removing the ones that are not compatible
 * with the carbon component
 * @param props {object} - The notification parameters
 * @returns {object} - The props for the ToastNotification component
 */
function getToastNotificationProps(props) {
  const toastNotificationProps = { ...props };

  delete toastNotificationProps.content;
  delete toastNotificationProps.children;
  delete toastNotificationProps.isClosing;
  delete toastNotificationProps.options;

  return toastNotificationProps;
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  kind: PropTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
  isClosing: PropTypes.bool,
  options: PropTypes.object
};

export default observer(Notification);
