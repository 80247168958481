/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { C7_EXECUTION_PLATFORM, EXECUTION_PLATFORM } from 'utils/constants';

const hasComponentsArray = (json) => json.hasOwnProperty('components') && Array.isArray(json.components);
const hasType = (json) => json.hasOwnProperty('type') && typeof json.type === 'string';
export const hasValidExecutionPlatform = (json) =>
  !json.hasOwnProperty('executionPlatform') ||
  (json.hasOwnProperty('executionPlatform') &&
    (json.executionPlatform === EXECUTION_PLATFORM || json.executionPlatform === C7_EXECUTION_PLATFORM));
export const hasValidExecutionPlatformVersion = (json) => json.hasOwnProperty('executionPlatformVersion');

export default function isValidForm(json) {
  try {
    const parsed = typeof json === 'object' ? json : JSON.parse(json);

    return hasComponentsArray(parsed) && hasType(parsed) && hasValidExecutionPlatform(parsed);
  } catch (err) {
    return false;
  }
}

export const isCamunda7Form = (formObj) => {
  return !formObj.hasOwnProperty('executionPlatform') || formObj.executionPlatform === C7_EXECUTION_PLATFORM;
};

export const isCamunda8Form = (formObj) => {
  return formObj.executionPlatform === EXECUTION_PLATFORM;
};
