/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Link } from '@carbon/react';

export const Wrapper = styled.div`
  .cds--modal-container {
    max-width: 640px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  padding: 0 12%;
`;

export const Visual = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 24px;
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: var(--cds-text-primary, #161616);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  margin-bottom: 8px;
`;

export const Message = styled.p`
  color: var(--cds-text-secondary, #525252);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;

  margin-bottom: 24px;
`;

export const InlineLink = styled(Link)`
  margin-top: 16px;
`;
