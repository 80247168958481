/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

class BaseTracingService {
  /**
   * @param {{ modelerUser: { id: String }}} _payload
   * @returns {void}
   */
  registerUser(_payload) {
    throw new Error('Not implemented');
  }

  /**
   * @returns {void}
   */
  logout = () => {
    throw new Error('Not implemented');
  };

  /**
   * @param {any} _error
   * @param {String} _customMessage
   * @returns {void}
   */
  traceError = (_error, _customMessage) => {
    throw new Error('Not implemented');
  };

  /**
   * @param {Function} _caller
   * @param {String} _message
   * @returns {void}
   */
  traceDebug = (_caller, _message) => {
    throw new Error('Not implemented');
  };
}

export default BaseTracingService;
