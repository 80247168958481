/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { organizationStore } from 'stores';
import { dedicatedModesStore, isExecuteModalVisibleStore } from 'App/Pages/Diagram/stores';
import { WithExternalLinkIcon } from 'primitives';

import * as Styled from './PlayModeInfo.styled';

export default function PlayModeInfo() {
  const { playModeIndex, setViewModeIndex } = dedicatedModesStore;
  const { hasPlayAccess } = organizationStore;

  const switchToPlayMode = () => {
    isExecuteModalVisibleStore.setIsVisible(false);
    setViewModeIndex(playModeIndex);
  };

  const openPlayDocumentation = () => {
    window.open('https://docs.camunda.io/docs/components/modeler/web-modeler/play-your-process/');
  };

  return (
    <Styled.Link onClick={hasPlayAccess ? switchToPlayMode : openPlayDocumentation}>
      or evaluate your process in {hasPlayAccess ? 'Play' : <WithExternalLinkIcon label="Play" />}
    </Styled.Link>
  );
}
