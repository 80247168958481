/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '@carbon/react';
import { Close } from '@carbon/icons-react';

import useFocusTrap from 'hooks/useFocusTrap';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import ClickOutsideListener from 'primitives/ClickOutsideListener/ClickOutsideListener';

import * as Styled from './NonModalDialog.styled';

// TODO(philippfromme): rename modal dialogs and non-modal dialogs according to
// see https://carbondesignsystem.com/patterns/dialog-pattern#modal-dialogs and
// https://carbondesignsystem.com/patterns/dialog-pattern#non-modal-dialogs
export default function NonModalDialog({
  children,
  maxWidth = '750px',
  onClose,
  onSubmit,
  open,
  primaryButtonText = 'Submit',
  primaryButtonDisabled = false,
  selectorPrimaryFocus
}) {
  const ref = useRef();

  useFocusTrap({ open, ref, selectorPrimaryFocus });

  const onKeyDown = useKeyboardTrap({ onClose, ref });

  return open
    ? createPortal(
        <Styled.NonModalDialog onKeyDown={onKeyDown} ref={ref} $maxWidth={maxWidth}>
          <ClickOutsideListener insideRef={ref} onClickOutside={onClose} />
          <Styled.Header>
            <Styled.CloseButton>
              <Button
                kind="ghost"
                renderIcon={Close}
                iconDescription="Close"
                tooltipPosition="left"
                hasIconOnly
                onClick={onClose}
                data-test="non-modal-dialog-close"
              />
            </Styled.CloseButton>
          </Styled.Header>
          <Styled.Body>{children}</Styled.Body>
          <Styled.Footer>
            <Button kind="primary" onClick={onSubmit} disabled={primaryButtonDisabled}>
              {primaryButtonText}
            </Button>
          </Styled.Footer>
        </Styled.NonModalDialog>,
        document.body
      )
    : null;
}
