/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import parseValidateAndAddEmails from 'components/MultiSelectInput/lib/parse-validate-and-add-emails';

export default function handlePaste(evt, validateAndAdd, isUsedInShare = false) {
  if (isUsedInShare) {
    parseValidateAndAddEmails(evt, validateAndAdd);
  } else {
    // If not used in shared, simply allow the user to paste text
    return;
  }
}
