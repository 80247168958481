/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, TextArea } from '@carbon/react';

import NonModalDialog from 'primitives/NonModalDialog/NonModalDialog';
import { authService } from 'services';
import { notificationStore, organizationStore } from 'stores';
import feedbackDialogStore from 'stores/FeedbackDialogStore';
import config from 'utils/config';

import * as Styled from './FeedbackDialog.styled';

export function FeebackDialog() {
  const [rating, setRating] = useState(null);
  const [suggestions, setSuggestions] = useState('');
  const [allowContact, setAllowContact] = useState(false);

  const onClose = useCallback(() => {
    feedbackDialogStore.close();

    setRating(null);
    setSuggestions('');
    setAllowContact(false);
  }, [feedbackDialogStore]);

  const onSubmit = useCallback(async () => {
    const type = feedbackDialogStore.state.type;

    const url = getUrl();

    const token = await authService.getToken();

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        allowContact: allowContact ? 'true' : 'false',
        reportType: 'feedback',
        salesPlanType: organizationStore.currentOrganizationInfo.salesPlan.type,
        suggestion: `Topic: ${type}; Rating: ${rating}; Suggestions: ${suggestions.trim().length > 0 ? suggestions : '-'}`
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 204) {
      notificationStore.showSuccess('Feedback has been sent.');
    }

    feedbackDialogStore.close();
  }, [allowContact, feedbackDialogStore, rating, suggestions]);

  return (
    <NonModalDialog
      maxWidth="23rem"
      onClose={onClose}
      onSubmit={onSubmit}
      open={feedbackDialogStore.state.isOpen}
      primaryButtonDisabled={!rating || !suggestions.trim().length}
      selectorPrimaryFocus=".suggestions textarea"
    >
      <Styled.Message>* {feedbackDialogStore.state.message}</Styled.Message>
      <Styled.RatingsButtons>
        {[1, 2, 3, 4, 5].map((value) => (
          <Button
            className="rating"
            data-rating={value}
            kind={value === rating ? 'primary' : 'tertiary'}
            key={value}
            onClick={() => setRating(value)}
            data-test={`rating-${value}`}
          >
            {value}
          </Button>
        ))}
      </Styled.RatingsButtons>
      <Styled.RatingsLabels>
        <span>Poor</span>
        <span>Excellent</span>
      </Styled.RatingsLabels>
      <Styled.Suggestions>
        <b>Do you have any suggestions?</b>
        <TextArea
          className="suggestions"
          rows={4}
          placeholder="Type your suggestions here"
          onChange={({ target }) => setSuggestions(target.value)}
          labelText=""
          data-test="textarea"
        />
      </Styled.Suggestions>
      <Styled.AllowContact>
        <Checkbox
          id="feedback-dialog-allowContact"
          checked={allowContact}
          onChange={() => setAllowContact(!allowContact)}
          labelText="Camunda may contact me about my feedback"
        />
      </Styled.AllowContact>
    </NonModalDialog>
  );
}

export default observer(FeebackDialog);

function getUrl() {
  const { camundaCloudBaseDomain } = config;

  return `https://helpcenter.${camundaCloudBaseDomain}/feedback/submit`;
}
