/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from 'services/Service';
import config from 'utils/config';
import {
  isDisallowedDomain,
  isProtocolAllowedOrMissing,
  isValidUrl,
  prependHttpsIfMissing
} from 'utils/validate-imported-resource';

class BaseImportService extends Service {
  /**
   * Returns the output of the import process. It should be overwritten by the child class to return the correct output.
   * @returns
   */
  async prepareOutput() {
    return {
      valid: false,
      error: null,
      reasons: [],
      templates: []
    };
  }

  /**
   * Fetches the resources and returns the output of the import process, composed of the valid and invalid resources.
   * @param {*} listOfResources The list of resources to import
   * @returns {Object} The output of the import process
   */
  async fetchResources(listOfResources) {
    const resources = this.#getUniqueListOfResources(listOfResources);
    const errors = [];
    const metadata = [];

    const results = await Promise.allSettled(resources.map((resource) => this.#fetchResource(resource)));
    for (const result of results) {
      const index = results.indexOf(result);
      if (result.status === 'fulfilled' && result.value) {
        const { valid, error, reasons, templates } = await this.prepareOutput(result.value);

        if (valid) {
          metadata.push({
            source: resources[index],
            templates
          });
        } else {
          errors.push({
            source: resources[index],
            error: error || 'Invalid schema',
            reasons,
            templates
          });
        }
      } else {
        errors.push({
          source: resources[index],
          error: result.reason || 'Unknown error'
        });
      }
    }

    return {
      errors,
      metadata
    };
  }

  // eslint-disable-next-line no-unused-vars
  extractFileName(template, _resource) {
    return template.name;
  }

  // eslint-disable-next-line no-unused-vars
  extractIcon(template, _resource) {
    return template?.icon?.contents;
  }

  // eslint-disable-next-line no-unused-vars
  executePostImportAction(history, selectedProject, _importedFiles) {
    this.#goToProject(history, selectedProject.id);
  }

  duplicatesNotAllowed() {
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  getAllDuplicatedResources(_projectId, _templateIdsToPrepare) {
    return [];
  }

  #filterResourcesByValidUrl(resources) {
    return resources.reduce((acc, resource) => {
      try {
        if (!isProtocolAllowedOrMissing(resource)) {
          return acc;
        }

        resource = prependHttpsIfMissing(resource);

        if (!isValidUrl(resource) || isDisallowedDomain(resource)) {
          return acc;
        }

        return [...acc, new URL(resource).toString()];
      } catch (e) {
        return acc;
      }
    }, []);
  }

  /**
   * Returns a list of valid and unique resources to import; it also sets the protocol to https if it's missing.
   * @param {*} resources The list of resources to import
   * @returns {Array} The list of unique resources to import
   */
  #getUniqueListOfResources(resources) {
    const validResources = this.#filterResourcesByValidUrl(resources);
    const uniqueList = new Set(validResources);
    return this.#limitTheNumberOfTemplates(Array.from(uniqueList));
  }

  async #fetchResource(url) {
    return this.get(`/fetch-resources?url=${url}`);
  }

  #limitTheNumberOfTemplates(templates) {
    if (templates.length > config.importResources.maxCountOfFiles) {
      return templates.slice(0, config.importResources.maxCountOfFiles);
    }

    return templates;
  }

  #goToProject(history, projectId) {
    history.push(`/projects/${projectId}`);
  }
}

export default BaseImportService;
