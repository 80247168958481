/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import config from 'utils/config';
import logLevels from 'utils/log-levels';

import BaseTracingService from './TracingService.common';

class TracingService extends BaseTracingService {
  constructor() {
    super();
    this.logLevel = config.logLevel;
  }

  /**
   * @type {BaseTracingService['registerUser']}
   */
  registerUser(_payload) {}

  /**
   * @type {BaseTracingService['logout']}
   */
  logout = () => {};

  /**
   * @type {BaseTracingService['traceError']}
   */
  traceError = (error, customMessage) => {
    if ([logLevels.ERROR, logLevels.WARN, logLevels.INFO, logLevels.DEBUG, logLevels.TRACE].includes(this.logLevel)) {
      console.error(error, customMessage);
    }
  };

  /**
   * @type {BaseTracingService['traceDebug']}
   */
  traceDebug = (caller, message) => {
    if ([logLevels.DEBUG, logLevels.TRACE].includes(this.logLevel)) {
      console.debug(`[${caller.name}]:`, message);
    }
  };
}

export default new TracingService();
