/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Spinner } from 'primitives';
import { organizationStore } from 'stores';
import { processTemplateService, projectService, trackingService } from 'services';
import { permissions } from 'utils/user-access';
import { sanitizeName } from 'utils/helpers';

import processTemplateStore from './Pages/Project/TemplatePicker/ProcessTemplateStore';
import * as Styled from './DeployableStarterBehavior.styled';

export function DeployableStarterBehavior() {
  const { id } = useParams();

  const [redirectTarget, setRedirectTarget] = useState();
  const isProcessing = useRef(false);

  const { currentOrganizationInfo, currentOrganizationId, isUsingTrial, isTrialExpired } = organizationStore;

  useEffect(() => {
    (async () => {
      if (!currentOrganizationInfo) {
        // current organization info is not loaded yet, therefore info about
        // trial usage and expiration cannot be accessed
        return;
      }

      if (isProcessing.current) {
        // prevent concurrent runs of the same effect
        return;
      }

      isProcessing.current = true;

      const templates = await processTemplateService.fetchAvailableTemplates();
      const template = templates.find(({ name }) => sanitizeName(name) === sanitizeName(id));

      if (!template) {
        setRedirectTarget('/');
        return;
      }

      const projects = await projectService.fetchByOrganizationId(currentOrganizationId, true);

      let tutorialProject = projects.find(
        ({ name, permissionAccess }) => name === 'Camunda Tutorials' && permissionAccess === permissions.ADMIN
      );

      if (!tutorialProject) {
        tutorialProject = await projectService.create({
          name: 'Camunda Tutorials',
          organizationId: currentOrganizationId
        });
        trackingService.trackCreateProject('deployable-starter-experiment');
      }

      await processTemplateStore.createDiagramFromSelectedTemplate(
        template,
        tutorialProject.id,
        'deployable-starter-experiment'
      );
    })();
  }, [currentOrganizationInfo, currentOrganizationId, isUsingTrial, isTrialExpired]);

  if (redirectTarget) {
    return <Redirect to={redirectTarget} />;
  }

  return (
    <Styled.Loading>
      <Spinner fullHeight />
    </Styled.Loading>
  );
}

export default observer(DeployableStarterBehavior);
