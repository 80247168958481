/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeObservable, observable, runInAction, action } from 'mobx';

import { tracingService, userService } from 'services';
import config from 'utils/config';

class BaseAuthService {
  isReady = false;
  hasReset = false;
  modelerUser = undefined;
  jwtUser = undefined;

  authClient = undefined;
  isInit = false;
  isAuthenticated = false;
  token = undefined;

  _tokenMinValidity = 30; // seconds

  constructor() {
    makeObservable(this, {
      isReady: observable,
      hasReset: observable,
      jwtUser: observable,
      token: observable,
      setToken: action
    });
  }

  /**
   * Triggers the token refresh and returns the current token.
   * @returns {Promise}
   */
  getToken() {
    return this.refreshToken();
  }

  setToken(value) {
    this.token = value;
  }

  async fetchJWTUser() {
    throw new Error('Not implemented');
  }

  async refreshToken() {
    throw new Error('Not implemented');
  }

  async getTokenAndFetchAuthProviderUser() {
    throw new Error('Not implemented');
  }

  async logout() {
    throw new Error('Not implemented');
  }

  async #fetchModelerUser() {
    tracingService.traceDebug(this.#fetchModelerUser, 'Fetching modeler user');
    try {
      const user = await userService.fetchMyself();
      if (user) {
        tracingService.traceDebug(this.#fetchModelerUser, 'Successfully fetched user');
        this.modelerUser = { ...user };
      } else {
        tracingService.traceDebug(this.#fetchModelerUser, 'User not found, logging out');
        this.logout();
      }
    } catch (ex) {
      tracingService.traceError(ex, null);
      this.logout();
    }
  }

  async prepareSession() {
    tracingService.traceDebug(this.prepareSession, 'Preparing session');
    await this.refreshToken();

    await this.fetchJWTUser();
    await this.createModelerUser();

    runInAction(() => {
      this.isReady = true;
    });
  }

  getReturnToFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let { returnUrl: returnTo } = Object.fromEntries(urlSearchParams.entries());

    if (returnTo) {
      returnTo = returnTo.replace(config.modelerBasePath, '');
    }

    if (returnTo === '/login' || !returnTo) {
      returnTo = '/';
    }

    return returnTo;
  }

  async createModelerUser() {
    tracingService.traceDebug(this.createModelerUser, 'Creating modeler user');
    await this.#upsertModelerUser();
    await this.#fetchModelerUser();
  }

  #upsertModelerUser() {
    tracingService.traceDebug(this.#upsertModelerUser, 'Upserting modeler user');
    return userService.upsertUser(this.#mapJwtDataToUser(this.jwtUser));
  }

  #mapJwtDataToUser(jwtData) {
    tracingService.traceDebug(this.#mapJwtDataToUser, 'Mapping JWT data to user');
    const { sub: iamId, name, email } = jwtData;

    const user = {
      iamId,
      name,
      email
    };

    // NOTE: This property is available only on Auth0
    const originalUserId = jwtData['https://camunda.com/originalUserId'];
    if (originalUserId) {
      user.originalUserId = originalUserId;
    }

    return user;
  }

  reset() {
    tracingService.traceDebug(this.reset, 'Resetting state of AuthService');
    this.token = undefined;
    this.modelerUser = undefined;
    this.jwtUser = undefined;

    runInAction(() => {
      this.isReady = false;
      this.hasReset = true;
    });
  }
}

export default BaseAuthService;
