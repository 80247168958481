/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const NonModalDialog = styled.div`
  position: fixed;
  right: 50%;
  bottom: 1rem;
  transform: translateX(50%);
  max-width: ${({ $maxWidth }) => $maxWidth};
  z-index: var(--z-dialog);
  background-color: var(--cm-color-white-base);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const Header = styled.div``;

export const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Body = styled.div`
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
