/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Log, UserManager } from 'oidc-client-ts';
import { runInAction } from 'mobx';

import history from 'utils/history';
import config from 'utils/config';
import { notificationStore } from 'stores';
import { OIDC_TYPE_GENERIC, OIDC_TYPE_KEYCLOAK, OIDC_TYPE_MICROSOFT } from 'utils/constants';
import logLevels from 'utils/log-levels';
import { tracingService } from 'services';

import BaseAuthService from './AuthService.common';

class AuthService extends BaseAuthService {
  async #init() {
    if (this.isInit) {
      return;
    }

    if (!this.authClient) {
      setLogLevelFromConfig();
      this.authClient = getUserManager();
      this.#registerEvents();
    }

    this.isInit = true;
  }

  #registerEvents() {
    this.authClient.events.addUserLoaded((user) => {
      this.setToken(user.access_token);
    });

    this.authClient.events.addAccessTokenExpiring(() => {
      this.authClient.signinSilent().catch((error) => {
        tracingService.traceError(error, 'Silent token refresh failed');
      });
    });

    this.authClient.events.addAccessTokenExpired(() => {
      tracingService.traceDebug(this.authClient.events.addAccessTokenExpired, 'Access token expired');
      this.#gracefulLogout();
    });

    this.authClient.events.addUserSignedOut(() => {
      tracingService.traceDebug(this.authClient.events.addUserSignedOut, 'User signed out');
      this.#gracefulLogout();
    });
  }

  // Deprecated method - kept to keep consistency with the common AuthService
  async refreshToken() {
    return this.token;
  }

  async fetchJWTUser() {
    tracingService.traceDebug(this.fetchJWTUser, 'Fetching JWT user');
    try {
      const user = await this.authClient.getUser();
      if (user?.profile) {
        runInAction(() => {
          this.jwtUser = { ...user.profile };
        });
      } else {
        tracingService.traceDebug(this.fetchJWTUser, 'User not found');
        this.logout();
      }
    } catch (error) {
      tracingService.traceError(error, 'Failed to fetch JWT user');
      this.logout();
    }
  }

  async loginWithRedirect() {
    tracingService.traceDebug(this.loginWithRedirect, 'Logging in with redirect');
    await this.#init();

    const returnTo = this.getReturnToFromUrl();

    sessionStorage.setItem('returnTo', returnTo);

    if (this.isAuthenticated) {
      this.#handlePersistedSession();
    } else {
      tracingService.traceDebug(this.loginWithRedirect, 'Redirecting to login page');
      await this.authClient.signinRedirect();
    }
  }

  async #handlePersistedSession() {
    tracingService.traceDebug(this.#handlePersistedSession, 'Handling persisted session');
    await this.prepareSession();

    const returnTo = sessionStorage.getItem('returnTo');
    sessionStorage.removeItem('returnTo');

    history.push(returnTo || '/');
  }

  async getTokenAndFetchAuthProviderUser() {
    if (this.token) {
      await this.fetchJWTUser();
    }
  }

  async handleRedirectCallback() {
    // Case 1: Silent refresh inside iframe
    const isSilentTokenRefreshInIframe = window.self !== window.top;
    if (isSilentTokenRefreshInIframe) {
      const userManager = getUserManager();
      userManager.signinCallback().catch((error) => {
        tracingService.traceError(error, 'Silent renew failed');
      });
      return;
    }

    // Case 2: End of initial authentication flow or other scenarios
    tracingService.traceDebug(this.handleRedirectCallback, 'Handling redirect callback');
    await this.#init();

    await this.authClient
      .signinCallback()
      .then(() => {
        this.isAuthenticated = true;
      })
      .catch(() => {
        this.isAuthenticated = false;
      });

    if (!this.isAuthenticated) {
      history.push('/login');
      return;
    }

    const returnTo = sessionStorage.getItem('returnTo');

    try {
      await this.getTokenAndFetchAuthProviderUser();
      await this.createModelerUser();

      runInAction(() => {
        this.isReady = true;
      });

      sessionStorage.removeItem('returnTo');

      history.push(returnTo || '/');
    } catch (error) {
      tracingService.traceError(error, 'Failed to handle redirect callback');
      this.logout();
    }
  }

  async logout() {
    tracingService.traceDebug(this.logout, 'Logging out');
    await this.#init();

    this.reset();

    await this.authClient.signoutRedirect();
  }

  async #gracefulLogout() {
    tracingService.traceDebug(this.#gracefulLogout, 'Logging out gracefully');
    notificationStore.showSuccess('Your session has expired, please log in again.');

    this.reset();
    await this.authClient.clearStaleState();

    setTimeout(() => {
      window.location?.replace(`${config.modelerUrl}/login?returnUrl=${window.location?.pathname}`);
    }, 5000);
  }
}

export default new AuthService();

const setLogLevelFromConfig = () => {
  /**
   * Mapping Camunda log levels to oidc-client-ts levels
   * */
  const logLevelMapping = {
    [logLevels.ERROR]: Log.ERROR,
    [logLevels.WARN]: Log.WARN,
    [logLevels.INFO]: Log.INFO,
    [logLevels.DEBUG]: Log.DEBUG,
    [logLevels.TRACE]: Log.DEBUG
  };
  const level = logLevelMapping[config?.logLevel];

  Log.setLogger(console);
  Log.setLevel(level);
};

/**
 * @param {number} level
 * @returns {string}
 */
// @ts-ignore
window.updateOidcClientLogLevel = (level) => {
  if ([Log.DEBUG, Log.ERROR, Log.INFO, Log.NONE, Log.WARN].includes(level)) {
    Log.setLevel(level);
    return 'OIDC client log level updated successfully!';
  } else {
    return 'Update unsuccessful! Please enter an integer between 0 and 4';
  }
};

const getUserManager = () => {
  const getOidcProviderSpecificConfiguration = () => {
    const defaultScopes = 'openid email profile';

    if ([OIDC_TYPE_GENERIC, OIDC_TYPE_KEYCLOAK].includes(config?.oAuth2?.type)) {
      return {
        scope: defaultScopes,
        // not supported by Microsoft Entra ID
        monitorSession: true
      };
    } else if (config?.oAuth2?.type === OIDC_TYPE_MICROSOFT) {
      return {
        scope: `${defaultScopes} ${config.oAuth2.clientId}/.default`,
        refreshTokenAllowedScope: ''
      };
    }
  };

  return new UserManager({
    ...getOidcProviderSpecificConfiguration(),
    authority: config.oAuth2.token.issuer,
    client_id: config.oAuth2.clientId,
    disablePKCE: false, // enable PKCE for code flow
    fetchRequestCredentials: config.oAuth2.client?.fetchRequestCredentials,
    post_logout_redirect_uri: `${config.modelerUrl}/login-callback`,
    redirect_uri: `${config.modelerUrl}/login-callback`,
    response_type: 'code',
    automaticSilentRenew: false,
    silent_redirect_uri: `${config.modelerUrl}/login-callback`
  });
};
