/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';

import { notificationStore, organizationStore, topBannerStore, userStore } from 'stores';
import { tracingService } from 'services';
import { ConfirmActionDialog, ErrorDisplay, TopBar } from 'components';
import Layovers from 'App/Layovers';
import { Snackbar, Spinner, TopBanner } from 'primitives';
import { transportCarrier } from 'transports';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';
import { Notifications } from 'components/NotificationSystem';
import FeedbackDialog from 'components/FeedbackDialog/FeedbackDialog';

import Home from './Pages/Home';
import Diagram from './Pages/Diagram/Diagram';
import Form from './Pages/Form/Form';
import ConnectorTemplate from './Pages/ConnectorTemplate/ConnectorTemplate';
import Project from './Pages/Project/Project';
import Folder from './Pages/Project/Folder';
import NoMatch from './Pages/NoMatch';
import Share from './Pages/Share/Share';
import NotificationSettings from './Pages/NotificationSettings/NotificationSettings';
import DiagramMilestones from './Pages/DiagramMilestones';
import FormMilestones from './Pages/FormMilestones';
import ConnectorTemplateVersions from './Pages/ConnectorTemplateVersions';
import DeployableStarterBehavior from './DeployableStarterBehavior';
import useAppCues from './useAppCues';
import useAuthentication from './useAuthentication';
import { useInitStatsigParams, WithStatsig } from './Statsig';
import { AppTeaser } from './Pages/AppTeaser';
import { UserConfigurationWrapper } from './UserConfigurationWrapper';

export const App = () => {
  const [isNewVersionMessageVisible, setIsNewVersionMessageVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, userAuthProviderId, userId } = userStore;
  const { currentOrganizationId, currentOrganizationInfo } = organizationStore;
  const isInShare = location.pathname.includes('/share/');

  useAuthentication();

  useEffect(() => {
    const preventDefault = (evt) => evt.preventDefault();

    window.addEventListener('dragover', preventDefault, false);
    window.addEventListener('drop', preventDefault, false);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const socket = transportCarrier.socket;
      const channel = socket?.subscribe('private-maintenance');

      channel?.bind('app:notifications:new-version', () => setIsNewVersionMessageVisible(true));
    }
  }, [isAuthenticated]);

  useAppCues({ history, currentOrganizationInfo, config, organizationStore, userAuthProviderId });

  const { isStatsigParamsInitialized, hashedUserId } = useInitStatsigParams(
    userId,
    currentOrganizationId,
    currentOrganizationInfo
  );

  // Statsig params (user and organization info) are not available in Share page
  const isStasigEnabled = config?.statsig?.enabled && !isInShare;

  if (isStasigEnabled && !isStatsigParamsInitialized) {
    return <Spinner fullHeight />;
  }

  return (
    <WithStatsig condition={isStasigEnabled} hashedUserId={hashedUserId}>
      <UserConfigurationWrapper>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
          <Helmet>
            <title>{getPageTitle()}</title>
          </Helmet>

          {isAuthenticated && !isInShare && <ConfirmActionDialog />}

          <TopBar />

          <TopBanner.OutdatedBrowserMessage
            onClose={topBannerStore.hideOutdatedBrowserMessage}
            open={topBannerStore.isOutdatedBrowserMessageVisible}
          />

          {isAuthenticated && (
            <Fragment>
              <TopBanner.UpdatedAppMessage open={isNewVersionMessageVisible} />

              <TopBanner.DownTimeBanner
                open={topBannerStore.isDownTimeBannerVisible}
                onClose={topBannerStore.hideDownTimeBanner}
                schedule={topBannerStore.downTimeSchedule}
              />

              <TopBanner.TrialBanner open={organizationStore.isUsingTrial} />
            </Fragment>
          )}

          <ErrorBoundary
            FallbackComponent={ErrorDisplay}
            resetKeys={[location.pathname]}
            onReset={() => history.push('/')}
            onError={tracingService.traceError}
          >
            <Switch>
              <Route path={['/', '/org/:orgId', '/import/connectors', '/import/processes']} exact>
                <Home />
              </Route>

              <Route path={['/login', '/login-callback', '/logout']} exact>
                <Spinner fullHeight />
              </Route>

              <Redirect from="/projects/:slug/join" to="/projects/:slug" />
              <Redirect from="/t/:slug" to="/projects/:slug" />
              <Redirect from="/d/:slug" to="/diagrams/:slug" />
              <Redirect from="/d/xml/:slug" to="/diagrams/xml/:slug" />

              <Redirect from="/projects" to="/" exact />
              <Route path="/projects/:slug" exact>
                <Project />
              </Route>
              <Route path="/tutorial/:id">
                <DeployableStarterBehavior />
              </Route>
              <Route path="/folders/:slug">
                <Folder />
              </Route>
              <Route path={['/diagrams/:slug', '/diagrams/xml/:slug']} exact>
                <Diagram />
              </Route>
              <Route path="/diagrams/:id/milestones/:slug?">
                <DiagramMilestones />
              </Route>
              <Route path="/forms/:slug" exact>
                <Form />
              </Route>
              <Route path="/forms/:id/milestones/:slug?">
                <FormMilestones />
              </Route>
              <Route path="/connector-templates/:slug" exact>
                <ConnectorTemplate />
              </Route>
              <Route path="/connector-templates/:id/versions/:slug?">
                <ConnectorTemplateVersions />
              </Route>
              <Route path={['/shares/:id', '/share/:id']}>
                <Share />
              </Route>
              <Route path="/notifications" exact>
                <NotificationSettings />
              </Route>
              <Route path="/appTeaser/operate">
                <AppTeaser appName="operate" />
              </Route>
              <Route path="/appTeaser/optimize">
                <AppTeaser appName="optimize" />
              </Route>
              <Route path="/appTeaser/tasklist">
                <AppTeaser appName="tasklist" />
              </Route>
              <Route>
                <NoMatch />
              </Route>
            </Switch>
          </ErrorBoundary>
          <Layovers />
          <Snackbar
            open={notificationStore.isNotificationVisible}
            onClose={notificationStore.hideNotification}
            onExited={notificationStore.resetNotification}
            variant={notificationStore.variant}
            message={notificationStore.message}
            progress={notificationStore.isProgress}
            duration={notificationStore.duration}
            data-test="snackbar"
          />
          <Notifications />
          <FeedbackDialog />
        </ErrorBoundary>
      </UserConfigurationWrapper>
    </WithStatsig>
  );
};

export default observer(App);
