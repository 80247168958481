/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function renderItems(items, Component, handleDelete) {
  const isUsedInShare = items.length > 0 && typeof items[0] === 'string';

  return isUsedInShare
    ? items?.map((email) => (
        <Component key={email} onDelete={() => handleDelete(email)}>
          {email}
        </Component>
      ))
    : items?.map(({ id, name }) => (
        <Component key={id} onDelete={() => handleDelete(id)}>
          {name}
        </Component>
      ));
}
