/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import { Cross } from 'icons';

import * as Styled from './Chip.styled';

export default function Chip({ children, onDelete, buttonTitle = `Remove ${children}`, ...props }) {
  return (
    <Styled.Wrapper data-test="chip" {...props}>
      {children}
      {onDelete && (
        <Styled.Button onClick={onDelete} title={buttonTitle} type="button" aria-label="Delete">
          <Cross width="14" height="14" />
        </Styled.Button>
      )}
    </Styled.Wrapper>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  buttonTitle: PropTypes.string
};
