/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { InboundConnectorDetail } from './';

export const InboundConnectorDetails = ({ clusterUrl, details, data }) => {
  return (
    <div>
      {data &&
        Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <InboundConnectorDetail title={key} value={getDetailValueFromDataValue(clusterUrl, key, value)} />
          </div>
        ))}
      {details &&
        Object.entries(details).map(([key, value]) => (
          <div key={key}>
            <InboundConnectorDetail title={key} value={value} />
          </div>
        ))}
    </div>
  );
};

const getDetailValueFromDataValue = (clusterUrl, key, value) => {
  if (key === 'path') {
    return generateWebhookUrlFromActiveClusterDetails(clusterUrl, value);
  } else {
    return value;
  }
};

/**
 * @param clusterUrl url of the cluster
 * @param path response returned by the Connectors API
 * @returns {string|*} the data that should be displayed in the Inbound Connector tab
 */
const generateWebhookUrlFromActiveClusterDetails = (clusterUrl, path) => {
  return `${clusterUrl}/inbound/${path}`;
};

export default observer(InboundConnectorDetails);
