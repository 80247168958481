/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Arrow } from 'icons';
import { formStore } from 'stores';
import { ErrorPanel, VersionSelector } from 'components';

import Errors from './Errors';

const FormErrorPanel = ({ lintErrors, collapsed, onCollapse }) => {
  const toggleCollapse = () => {
    onCollapse(!collapsed);
  };

  return (
    <div role="tablist" aria-expanded={!collapsed} data-test="form-error-panel">
      <ErrorPanel.Title
        onClick={(e) => e.target === e.currentTarget && onCollapse(!collapsed)}
        data-test="error-panel-title"
      >
        <ErrorPanel.Tab role="tab" onClick={toggleCollapse} $active $isErrorPanelCollapsed={collapsed}>
          <ErrorPanel.StatusText>Problems</ErrorPanel.StatusText>
          <ErrorPanel.Badge>{lintErrors?.length}</ErrorPanel.Badge>
        </ErrorPanel.Tab>

        <ErrorPanel.MenuRight>
          <VersionSelector
            message="Your form is checked against the selected engine version."
            showClusters={false}
            executionPlatformVersion={formStore.executionPlatformVersion}
            onSelectExecutionPlatformVersion={formStore.setExecutionPlatformVersion}
            showDocumentationLink={false}
            isReadOnly={formStore.hasPermissionToOnlyViewProperties}
          />

          <ErrorPanel.Collapse $isCollapsed={collapsed} onClick={toggleCollapse} data-test="error-panel-collapse">
            <Arrow width="12" />
          </ErrorPanel.Collapse>
        </ErrorPanel.MenuRight>
      </ErrorPanel.Title>

      {!collapsed && (
        <ErrorPanel.Errors>
          <Errors errors={lintErrors} />
        </ErrorPanel.Errors>
      )}
    </div>
  );
};

export default observer(FormErrorPanel);
