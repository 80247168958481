/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { sanitize } from 'dompurify';

import { Diagram } from 'icons';
import { milestoneStore, projectStore, userStore } from 'stores';
import { tracingService, trackingService } from 'services';
import { BPMN, IMPORT_DIALOG_SOURCE } from 'utils/constants';
import { validateFiles } from 'utils/file-io';

import BaseImportService from './BaseImportService';

class ImportProcessTemplateService extends BaseImportService {
  async prepareOutput(template) {
    let resultTemplate = template;
    let files = [
      {
        size: new Blob([resultTemplate]).size,
        type: BPMN,
        content: resultTemplate
      }
    ];

    const response = await validateFiles(files, [BPMN]);
    let { valid: validFiles, invalid: invalidFiles } = response;

    if (validFiles?.length > 0) {
      return {
        valid: true,
        error: undefined,
        reasons: undefined,
        templates: [template]
      };
    } else if (invalidFiles?.length > 0) {
      return {
        valid: false,
        error: 'Invalid content',
        reasons: ['File validation failed'],
        templates: [template]
      };
    }
  }

  async import({ selectedProject, resourcesMetadata }) {
    const resource = resourcesMetadata[0];
    const template = resource.templates[0];
    const projectId = selectedProject.id;
    const fileName = this.extractFileName(template, resource);

    const baseFile = {
      type: BPMN,
      name: fileName,
      projectId,
      importUrl: resource.source,
      originAppInstanceId: userStore.originAppInstanceId,
      content: template
    };

    const file = await projectStore.addAdditionalAttributesToFile(baseFile);

    try {
      const createdFile = await projectStore.createFile({
        type: BPMN,
        source: IMPORT_DIALOG_SOURCE,
        isFromTemplate: false,
        file
      });

      if (!createdFile) {
        const error = new Error('Could not create file');
        tracingService.traceError(error);
        return Promise.reject(error);
      }

      await milestoneStore.create({
        file: createdFile,
        append: false,
        name: 'Marketplace resource',
        organizationPublic: false,
        origin: 'publish'
      });

      trackingService.trackImportedResourcePublish([resource.source], [projectId]);

      return [createdFile];
    } catch (e) {
      tracingService.traceError(e);
      return Promise.reject(e);
    }
  }

  extractFileName(_template, resource) {
    const url = new URL(resource.source);
    const pathname = url.pathname;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    const decodedResult = decodeURIComponent(fileName);
    const sanitizedResult = sanitize(decodedResult);
    return sanitizedResult
      .replace(/&nbsp;|-|\+/g, ' ') // replace encoded whitespace with space
      .replace(/\.[^/.]+$/, '') // remove file extension
      .substring(0, 255); // use only first 255 characters
  }

  // eslint-disable-next-line no-unused-vars
  extractIcon(_template, _resource) {
    return <Diagram width="20" height="20" alt={`Process template logo`} />;
  }

  executePostImportAction(history, _selectedProject, importedProcesses) {
    this.#goToProcess(history, importedProcesses[0].id);
  }

  async fetchResources(listOfResources) {
    return super.fetchResources([listOfResources[0]]);
  }

  #goToProcess(history, processId) {
    history.push(`/diagrams/${processId}`);
  }
}

export default new ImportProcessTemplateService();
