/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { organizationStore } from 'stores';

const EARLY_ACCESS_FEATURE = 'aifeatures';

export default function useAreAiFeaturesEnabled() {
  const isAiFeaturesEnabled =
    ['enterprise', 'internal'].includes(organizationStore.currentOrganizationInfo?.salesPlan?.type) &&
    organizationStore.currentOrganizationInfo?.hasAcceptedBetaTerms &&
    organizationStore.currentOrganizationInfo?.earlyAccessFeatures?.includes(EARLY_ACCESS_FEATURE);

  return isAiFeaturesEnabled;
}
