/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close, Renew, Upgrade } from '@carbon/icons-react';
import { Button as CarbonButton } from '@carbon/react';

import { organizationStore } from 'stores';
import { trackingService } from 'services';
import config from 'utils/config';

import * as Styled from './TopBanner.styled';

export default function TopBanner({ open, onClose, children, isTrialBanner, dataTest = 'banner', ...props }) {
  if (!open) {
    return null;
  }

  return (
    <Styled.Wrapper role="banner" data-test={dataTest} $isTrialBanner={isTrialBanner} {...props}>
      {children}

      {onClose && (
        <CarbonButton
          data-test="close-top-banner"
          hasIconOnly
          size="sm"
          iconDescription="Dismiss"
          tooltipPosition="bottom"
          tooltipAlignment="end"
          kind="ghost"
          renderIcon={Close}
          onClick={onClose}
        />
      )}
    </Styled.Wrapper>
  );
}

const TrialBanner = (props) => {
  const [showBanner, setShowBanner] = useState(true);
  useEffect(() => {
    if (props.open && organizationStore.isTrialExpired) {
      trackingService.incrementExpiredTrialCounter();
    }
  }, [props.open]);

  const message = organizationStore.isTrialExpired
    ? 'Your trial has expired'
    : `Modeling is free, but your free process execution trial ends in ${organizationStore.remainingTrialDays}`;

  let cta;
  if (organizationStore.hasBillingPermission) {
    cta = (
      <Styled.Link
        href={`https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/checkout`}
        target="_blank"
        rel="noopener"
      >
        <CarbonButton
          data-test="upgrade-button"
          size="sm"
          renderIcon={Upgrade}
          kind="tertiary"
          onClick={() => {
            const displayedSalesPlan = organizationStore.currentOrganizationInfo?.salesPlan?.type;
            trackingService.trackCheckoutPage(displayedSalesPlan);
          }}
        >
          Upgrade now
        </CarbonButton>
      </Styled.Link>
    );
  }
  if (organizationStore.isTrialExpired && !organizationStore.hasBillingPermission) {
    cta = <Styled.Message>. Contact the owner or admin to upgrade your plan.</Styled.Message>;
  }

  return (
    <TopBanner {...props} isTrialBanner open={props.open && showBanner}>
      <Styled.Message>{message}</Styled.Message>
      {cta}

      <CarbonButton
        data-test="close-top-banner"
        hasIconOnly
        size="sm"
        iconDescription="Dismiss"
        tooltipPosition="bottom"
        tooltipAlignment="end"
        kind="ghost"
        renderIcon={Close}
        onClick={() => setShowBanner(false)}
      />
    </TopBanner>
  );
};

const OutdatedBrowserMessage = (props) => (
  <TopBanner variant="warning" {...props}>
    <strong>Your browser is outdated.</strong>&nbsp;For the best experience, keep your browser up to date. Or
    consider&nbsp;{' '}
    <a rel="noopener noreferrer" href="http://outdatedbrowser.com/en" target="_blank">
      choosing a different browser
    </a>
    , they are pretty awesome.
  </TopBanner>
);

// NOTE: this banner is currently not in use, but might be used in future
// privacy policy changes.
const PrivacyPolicyMessage = (props) => (
  <TopBanner {...props}>
    Check out our{' '}
    <a rel="noopener noreferrer" href="https://camunda.com/legal/privacy/" target="_blank">
      new privacy policy
    </a>
    .
  </TopBanner>
);

const DownTimeBanner = (props) => (
  <TopBanner {...props}>
    <strong>Maintenance Downtime:</strong>&nbsp;Modeler will be unavailable between&nbsp;{' '}
    <strong>
      {props.schedule.startDate} - {props.schedule.startTime}
    </strong>{' '}
    &nbsp;and&nbsp;{' '}
    <strong>
      {props.schedule.endDate} - {props.schedule.endTime}
    </strong>
    .&nbsp;We apologize for any inconvenience.
  </TopBanner>
);

const UpdatedAppMessage = (props) => (
  <TopBanner {...props}>
    <strong>Good News!</strong>&nbsp;A new Modeler experience is ready&nbsp;&nbsp;
    <CarbonButton kind="tertiary" size="sm" renderIcon={Renew} onClick={() => window.location.reload()}>
      Refresh now
    </CarbonButton>
  </TopBanner>
);

TopBanner.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.string,
  onClose: PropTypes.func,
  dataTest: PropTypes.string
};

TopBanner.defaultProps = {
  variant: 'info',
  open: false
};

TopBanner.OutdatedBrowserMessage = OutdatedBrowserMessage;
TopBanner.PrivacyPolicyMessage = PrivacyPolicyMessage;
TopBanner.UpdatedAppMessage = UpdatedAppMessage;
TopBanner.DownTimeBanner = DownTimeBanner;
TopBanner.TrialBanner = TrialBanner;
