/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Radio } from 'primitives';
import { Arrow } from 'icons';
import { Dropdown } from 'components';
import { AVAILABLE_ALPHA_LINT_VERSIONS, AVAILABLE_LINT_VERSIONS } from 'utils/constants';
import pluralize from 'utils/pluralize';
import { clustersStore } from 'stores';
import { parseClusterZeebeVersion } from 'App/Pages/Diagram/Deployment/cluster-version-util';

import Selector from './Selector';
import * as Styled from './VersionSelector.styled';

export const NoOfClustersMessage = ({ noOfClusters }) => {
  let message = '';
  if (noOfClusters) {
    message = `${noOfClusters} ${pluralize('cluster', noOfClusters)} available`;
  } else {
    message = 'No cluster found';
  }

  return <Styled.NoOfClusters>{message}</Styled.NoOfClusters>;
};

export const VersionSelector = ({
  executionPlatformVersion,
  message,
  showClusters,
  onSelectExecutionPlatformVersion,
  showDocumentationLink = true,
  isReadOnly = false
}) => {
  const selectionRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  const availableVersions = AVAILABLE_ALPHA_LINT_VERSIONS.map((version) => ({ version, isAlpha: true })).concat(
    AVAILABLE_LINT_VERSIONS.map((version) => ({ version, isAlpha: false }))
  );
  const top3Versions = availableVersions.slice(0, 3);
  const isSelectedVersionAmongTop3 = top3Versions.some(({ version }) => version === executionPlatformVersion);
  const [showOnlyTop3, setShowOnlyTop3] = useState(isSelectedVersionAmongTop3);

  const generateEngineToNumClustersMapping = () => {
    const mapping = {};
    clustersStore.clusters?.forEach((cluster) => {
      const versionInfo = parseClusterZeebeVersion(cluster.generation?.versions?.zeebe);
      if (versionInfo) {
        const { major, minor } = versionInfo;
        const engineVersion = `${major}.${minor}.0`;
        if (AVAILABLE_ALPHA_LINT_VERSIONS.concat(AVAILABLE_LINT_VERSIONS).includes(engineVersion)) {
          if (!mapping.hasOwnProperty(engineVersion)) {
            mapping[engineVersion] = 0;
          }
          mapping[engineVersion]++;
        }
      }
    });
    return mapping;
  };

  const engineToNumClustersAvailable = generateEngineToNumClustersMapping();

  const renderLintVersion = (version, isAlpha) => {
    return (
      <Styled.Version key={version} $showClusters={showClusters} role="menuitem">
        <Styled.Label>
          <Styled.VersionColumn>
            <Styled.Radio>
              <Radio
                name={version}
                value={version}
                selected={executionPlatformVersion}
                onSelect={() => onSelectExecutionPlatformVersion(version)}
              />
            </Styled.Radio>
          </Styled.VersionColumn>
          <Styled.VersionColumn>
            <Styled.RadioLabel>
              Zeebe {version.substring(0, 3)}
              {isAlpha && <Styled.Tag>alpha</Styled.Tag>}
              {showClusters && <NoOfClustersMessage noOfClusters={engineToNumClustersAvailable[version]} />}
            </Styled.RadioLabel>
          </Styled.VersionColumn>
        </Styled.Label>
      </Styled.Version>
    );
  };

  const expandAndReattachDropdown = () => {
    setShowOnlyTop3(false);
    setAnchorEl(null);
    setTimeout(() => {
      setAnchorEl(selectionRef.current);
    }, 5);
  };

  return (
    <Styled.VersionSelector $isReadOnly={isReadOnly}>
      <Selector
        setAnchorEl={setAnchorEl}
        executionPlatformVersion={executionPlatformVersion}
        selectionRef={selectionRef}
        isReadOnly={isReadOnly}
      />
      <Dropdown open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} align="left">
        <Styled.Dropdown>
          <strong>Select Zeebe version</strong>
          <div>{message}</div>
          {showDocumentationLink && (
            <Styled.Link
              onClick={() =>
                window.open(
                  'https://docs.camunda.io/docs/components/modeler/web-modeler/fix-problems-in-your-diagram/#engine-version-selection',
                  '_blank'
                )
              }
            >
              Learn more
            </Styled.Link>
          )}
          <Styled.Versions>
            {showOnlyTop3
              ? top3Versions.map(({ version, isAlpha }) => renderLintVersion(version, isAlpha))
              : availableVersions.map(({ version, isAlpha }) => renderLintVersion(version, isAlpha))}

            {showOnlyTop3 && (
              <Styled.ShowOlderVersions onClick={expandAndReattachDropdown}>
                <Styled.Collapse>
                  <Arrow width="12" />
                </Styled.Collapse>
                <Styled.Link>Show older versions</Styled.Link>
              </Styled.ShowOlderVersions>
            )}
          </Styled.Versions>
        </Styled.Dropdown>
      </Dropdown>
    </Styled.VersionSelector>
  );
};

VersionSelector.propTypes = {
  executionPlatformVersion: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showClusters: PropTypes.bool,
  onSelectExecutionPlatformVersion: PropTypes.func.isRequired
};

export default observer(VersionSelector);
