/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import feedbackDialogStore from 'stores/FeedbackDialogStore';

class FeedbackExtension {
  constructor(eventBus) {
    eventBus.on('contextPad.feedback', () => {
      feedbackDialogStore.open({
        message: 'How was your overall experience using the new context menu?',
        type: 'newContextPad'
      });
    });
  }
}

FeedbackExtension.$inject = ['eventBus'];

export default {
  __init__: ['feedbackExtension'],
  feedbackExtension: ['type', FeedbackExtension]
};
