/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import React, { useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@carbon/react';
import PropTypes from 'prop-types';

import { breadcrumbStore, organizationStore, userStore } from 'stores';
import buildPath from 'utils/build-path';

import * as Styled from './TopBar.styled';
import {
  useAppsAndClusters,
  useHelpCenterAndNotifications,
  useInfoLinks,
  useNavbar,
  useOrganizations,
  useReloadClustersOnClusterCreationUpgradeOrDeletion,
  useUser
} from './hooks';

const TopBarCommon = ({
  showAppBar = true,
  showOrganizations = true,
  showClusters = true,
  showCookiePreferences = true,
  termsOfUseUrl,
  forwardRefLink
}) => {
  const history = useHistory();

  const { currentOrganizationInfo } = organizationStore;

  const appBar = useAppsAndClusters({ showClusters, showAppBar });
  const orgSideBar = useOrganizations({ showOrganizations });
  const { helpCenterConfig, notificationsConfig } = useHelpCenterAndNotifications();
  const infoSideBar = useInfoLinks();
  const userSideBar = useUser({ termsOfUseUrl, showCookiePreferences });
  const navbar = useNavbar({ orgName: currentOrganizationInfo?.name });
  useReloadClustersOnClusterCreationUpgradeOrDeletion();

  const hideAppBar = !showAppBar || appBar?.elements?.length === 0;

  return (
    <Styled.Wrapper data-test="top-bar" $hideApps={hideAppBar}>
      <Theme theme="g10">
        <Styled.Navigation
          app={{
            ariaLabel: 'Camunda Modeler',
            name: 'Modeler',
            routeProps: {
              className: 'clickable',
              onClick: () => {
                if (userStore.isAuthenticated) {
                  history.push('/');
                } else {
                  window.location.assign(buildPath('/login'));
                }
              }
            }
          }}
          navbar={navbar}
          appBar={appBar}
          orgSideBar={orgSideBar}
          infoSideBar={infoSideBar}
          userSideBar={userSideBar}
          helpCenter={helpCenterConfig}
          notificationSideBar={notificationsConfig}
          forwardRef={forwardRefLink}
        />
      </Theme>

      <Styled.Breadcrumbs data-test="breadcrumbs" $appBarHidden={hideAppBar}>
        <Observer>{() => breadcrumbStore.breadcrumbs}</Observer>
      </Styled.Breadcrumbs>

      <Styled.SubHeader>
        <Observer>{() => breadcrumbStore.subHeader}</Observer>
      </Styled.SubHeader>
    </Styled.Wrapper>
  );
};

const Breadcrumbs = (props) => {
  // Each child (breadcrumb) needs to have a key prop, since it is
  // rendered as an uncontrolled component in TopBar.js. By looping over
  // `cloneElement` here, said key is set automatically.
  const children = React.Children.map(props.children, (child) => {
    if (child) {
      return React.cloneElement(child);
    }
  });

  useEffect(() => {
    breadcrumbStore.setBreadcrumbs(children);
  }, [children]);

  return null;
};

const SubHeader = ({ children }) => {
  useEffect(() => {
    breadcrumbStore.setSubHeader(children);

    return () => breadcrumbStore.setSubHeader(null);
  }, [children]);

  return null;
};

TopBarCommon.Breadcrumbs = Breadcrumbs;
TopBarCommon.SubHeader = SubHeader;

TopBarCommon.propTypes = {
  showAppBar: PropTypes.bool,
  showOrganizations: PropTypes.bool,
  showClusters: PropTypes.bool,
  showCookiePreferences: PropTypes.bool,
  termsOfUseUrl: PropTypes.string
};

export default observer(TopBarCommon);
