/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { InboundConnectorLog } from './';
import * as Styled from './InboundConnector.styled';

export const InboundConnectorLogs = ({ logs, initialLogLoadingInProgress }) => {
  return (
    <Styled.LogsContainer>
      <Styled.LastActivitiesTitle>Last activities</Styled.LastActivitiesTitle>
      {!initialLogLoadingInProgress ? (
        <>
          {logs?.length > 0 ? (
            <>
              {logs.map((log) => (
                <div key={log?.timestamp}>
                  <InboundConnectorLog log={log} />
                </div>
              ))}
            </>
          ) : (
            <div>No activities recorded</div>
          )}
        </>
      ) : (
        <>
          <Styled.Loading description={`Loading activity logs`} />
        </>
      )}
    </Styled.LogsContainer>
  );
};

export default observer(InboundConnectorLogs);
