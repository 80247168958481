/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class InvalidDiagramDialogStore {
  isOpen = false;
  hasBeenDismissed = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.isOpen = false;
    this.hasBeenDismissed = false;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
    this.setHasBeenDismissed();
  }

  setHasBeenDismissed() {
    this.hasBeenDismissed = true;
  }
}

export default new InvalidDiagramDialogStore();
