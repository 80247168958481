/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import semver from 'semver';

/**
 *
 * Input (string): camunda/zeebe:8.4.0-alpha2
 * Ouput (object): Parsed version object
 * {
 *     major: 8,
 *     minor: 4,
 *     patch: 0,
 *     prerelease: [alpha2],
 *     ...rest
 * }
 */
export const parseClusterZeebeVersion = (zeebeVersion) => {
  const versionMatch = zeebeVersion.match(/(\d+\.\d+\.\d+-?\w*)/);

  if (versionMatch?.length > 0) {
    const version = versionMatch[1];
    return semver.parse(version);
  }
  return null;
};

/**
 *
 * Input (string) : camunda/zeebe:8.3.0-alpha3
 * Output (string): 8.3.0-alpha3 (default)
 * Output (string): 8.3 (includePatch = false, includePrerelease = false)
 *
 * See the test specification for edge cases
 */
export const getSanitizedZeebeVersionString = (
  zeebeVersion,
  { includePatch = true, includePrerelease = true } = {}
) => {
  if (zeebeVersion) {
    const parsedVersion = parseClusterZeebeVersion(zeebeVersion);
    if (parsedVersion) {
      const { major, minor, patch, prerelease } = parsedVersion;
      return `${major}.${minor}${includePatch ? `.${patch}` : ''}${includePrerelease && prerelease?.length > 0 ? `-${prerelease}` : ''}`;
    }

    if (zeebeVersion.startsWith('camunda/zeebe:')) {
      return zeebeVersion.substring('camunda/zeebe:'.length);
    }
  }
  return zeebeVersion;
};
