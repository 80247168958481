/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { authService } from 'services';
import config from 'utils/config';
import buildPath from 'utils/build-path';

import PusherBatchAuthorizer from './PusherBatchAuthorizer';
import { BaseWebSocket } from './WebSocket.common';

export class WebSocket extends BaseWebSocket {
  createSocket() {
    const encrypted = config.pusher.forceTLS;

    let pusherOptions = {
      disableStats: true,
      channelAuthorization: {
        endpoint: buildPath('/pusher/auth'),
        customHandler: (channel, callback) => PusherBatchAuthorizer(channel, callback, pusherOptions)
      },
      auth: async () => ({
        headers: {
          Authorization: `Bearer ${await authService.getToken()}`
        }
      }),
      authDelay: 500,
      cluster: 'eu',
      wsHost: config.pusher.host,
      forceTLS: encrypted
    };

    if (encrypted) {
      pusherOptions = {
        ...pusherOptions,
        wssPort: config.pusher.port,
        enabledTransports: ['ws', 'wss'],
        disabledTransports: ['xhr_streaming', 'xhr_polling', 'sockjs']
      };
    } else {
      pusherOptions = {
        ...pusherOptions,
        wsPort: config.pusher.port,
        enabledTransports: ['ws'],
        disabledTransports: ['wss', 'xhr_streaming', 'xhr_polling', 'sockjs']
      };
    }
    if (config.pusher.path !== '/') {
      pusherOptions.wsPath = config.pusher.path;
    }

    this.instanciateSocket(pusherOptions);
  }
}
