/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { breadcrumbStore, projectStore } from 'stores';
import { Button } from 'primitives';
import { BPMN } from 'utils/constants';
import buildSlug from 'utils/buildSlug';

import ProjectActionButton from './ProjectActionButton';

export function EmptyStateActionButtons() {
  const { uploadFiles } = projectStore;

  const [isCreating, setIsCreating] = useState(false);
  const history = useHistory();

  const handleCreateBpmn = async () => {
    setIsCreating(true);

    const diagram = await projectStore.createFile({ type: BPMN });

    setIsCreating(false);

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  };

  return (
    <>
      <Button onClick={handleCreateBpmn} disabled={isCreating}>
        Create new diagram
      </Button>
      <ProjectActionButton variant="secondary" onUpload={uploadFiles} label="Create new" />
    </>
  );
}

export default observer(EmptyStateActionButtons);
