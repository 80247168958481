/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class ClusterCreationModalStore {
  isVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  open = () => {
    this.#setIsVisible(true);
  };

  close = () => {
    this.#setIsVisible(false);
  };

  #setIsVisible(isVisible) {
    this.isVisible = isVisible;
  }
}

export default new ClusterCreationModalStore();
