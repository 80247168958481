/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { EntityTable, ShareView } from 'components';
import { NameRenderer } from 'components/EntityTable/CellRenderer';
import { EmptyState, Spinner } from 'primitives';
import { DiagramShapes, Folder, SadFace } from 'icons';
import { diagramExtensionStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { getAuthorName, getEntityIcon, getEntityLink, getEntityName, isFile } from 'utils/helpers';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

import FolderHeader from './FolderHeader';
import ActionButton from './ActionButton';
import getBatchActions from './BatchActions';
import EntityAction from './EntityAction';
import { getFileDate } from './utils';
import * as Styled from './Shared.styled';

const FolderPage = () => {
  const { slug } = useParams();
  const { project, folder, loading, entities } = projectStore;

  const { batchActions, targetSelector } = getBatchActions();

  useEffect(() => {
    projectStore.init('folder', slug.split('--')[0]);

    return () => projectStore.reset();
  }, [slug]);

  return (
    <Fragment>
      <FolderHeader />

      {loading ? (
        <Spinner fullHeight />
      ) : folder.id ? (
        <Styled.Container>
          <EntityTable
            action={({ tabIndex }) => <ActionButton onUpload={projectStore.uploadFiles} tabIndex={tabIndex} />}
            batchActions={batchActions}
            emptyState={
              <EmptyState
                icon={<DiagramShapes />}
                title="This folder doesn't have any content yet."
                description={`Everyone with access to the project "${project.name}" also has access to the content in this folder.`}
                action={<ActionButton label="Create new" variant="primary" onUpload={projectStore.uploadFiles} />}
              />
            }
            columns={[
              { key: 'name', header: 'Name', renderer: NameRenderer, noPadding: true, sortable: true },
              { key: 'creator', header: 'Creator', width: '150px', sortable: true },
              { key: 'lastChanged', header: 'Last changed', width: '200px', sortable: true },
              {
                key: 'entityAction',
                header: '',
                width: '50px',
                noPadding: true,
                renderer: (entity) => <EntityAction entity={entity} />
              }
            ]}
            onDrop={hasAccess(project, actions.MODIFY_DIAGRAM) && projectStore.uploadFiles}
            title={
              <Styled.Title data-test="folder-name">
                <Folder width="24" height="24" />
                {folder.name}
              </Styled.Title>
            }
            rows={entities.map((entity) => ({
              __sortingPosition: isFile(entity) ? 2 : 1,
              id: entity.id,
              name: {
                name: getEntityName(entity),
                icon: getEntityIcon(entity),
                link: getEntityLink(entity),
                onClick: () => {
                  if (entity.type === 'BPMN') {
                    dedicatedModesStore.resetViewModeIndexForPlay();
                  }
                }
              },
              creator: getAuthorName(entity),
              lastChanged: getFileDate(entity),
              entityAction: entity
            }))}
          />

          {diagramExtensionStore.state.isShareModalVisible && (
            <ShareView diagram={diagramExtensionStore.state.diagram} onClose={diagramExtensionStore.hideShareModal} />
          )}

          {targetSelector}
        </Styled.Container>
      ) : (
        <EmptyState
          title="Folder not found!"
          description="Sorry, your folder might have been deleted."
          icon={<SadFace width="48" height="48" />}
        />
      )}
    </Fragment>
  );
};

export default observer(FolderPage);
