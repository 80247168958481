/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

// File contains the hard-coded version of the client and webapp, will be
// overwritten by CI build, do not move or modify manually!
export const version = 'cdd6f1e7acd9856eb31a5648ebdc05746a9e220b';

export const versionShort = (() => {
  return version.substring(0, 7);
})();
