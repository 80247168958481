/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';

import { JWT_ORGANIZATION_CLAIM } from 'utils/constants';
import { authService, consoleService, tracingService, trackingService } from 'services';
import { organizationStore } from 'stores';

class UserStore {
  originAppInstanceId = v4();
  isAuthenticated = false;

  userId = undefined;
  userAuthProviderId = undefined;
  userEmail = '';
  userName = '';
  _isSuperUserModeEnabled = false;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      userId: observable,
      userAuthProviderId: observable,
      userName: observable,
      userEmail: observable,
      _isSuperUserModeEnabled: observable,
      initIsSuperUserModeEnabled: action,
      toggleSuperUserMode: action
    });

    this.initIsSuperUserModeEnabled();
    this.#observeServices();
  }

  #observeServices() {
    autorun(() => {
      if (authService.isReady) {
        this.#onUserReady();
      } else if (authService.hasReset) {
        this.reset();
      }
    });
  }

  initIsSuperUserModeEnabled() {
    this._isSuperUserModeEnabled = JSON.parse(window.localStorage.getItem('modeler.super-user-mode.enabled'));
  }

  toggleSuperUserMode() {
    window.localStorage.setItem('modeler.super-user-mode.enabled', JSON.stringify(!this._isSuperUserModeEnabled));
    this._isSuperUserModeEnabled = !this._isSuperUserModeEnabled;
  }

  reset() {
    runInAction(() => {
      this.isAuthenticated = false;
    });
  }

  get userOrganizations() {
    return authService.jwtUser ? authService.jwtUser[JWT_ORGANIZATION_CLAIM] : [];
  }

  get userRolesInCurrentOrganization() {
    return this.userOrganizations?.find((org) => org.id === organizationStore.currentOrganizationId)?.roles ?? [];
  }

  get isOrgOwner() {
    return this.userRolesInCurrentOrganization?.includes('owner');
  }

  get isOrgAdmin() {
    return this.userRolesInCurrentOrganization?.includes('admin');
  }

  get isOrgOwnerOrAdmin() {
    return this.isOrgOwner || this.isOrgAdmin;
  }

  get isSuperAdminModeActive() {
    return this.isOrgOwnerOrAdmin && this._isSuperUserModeEnabled;
  }

  get isLoading() {
    return document.location.href.includes('/login') || document.location.href.includes('/logout');
  }

  /**
   * Compares two user accounts and returns `true` if they
   * are equal or `false` if they are different.
   *
   * @param {Object} user The user to compare with.
   * @returns {Boolean}
   */
  isCurrentUser = (user) => {
    if (!user) {
      return false;
    }

    return this.isAuthenticated && authService.modelerUser?.id === user.id;
  };

  /**
   *
   * @param {*} originAppInstanceId
   */
  isCurrentOriginAppInstanceId(originAppInstanceId) {
    return this.originAppInstanceId === originAppInstanceId;
  }

  /**
   * Checks if the current user has revoked roles in the current organization.
   * If so, the token is forcefully refreshed.
   * @returns {Promise<Boolean>} `true` if the token was refreshed, `false` otherwise.
   */
  async checkRevokedRoles() {
    if (!organizationStore.currentOrganization || !organizationStore.hasRevocableRoles) {
      return false;
    }

    await organizationStore.fetchOrganizationInfoFromConsole(organizationStore.currentOrganizationId);

    const members = await consoleService.getOrganizationMembers({
      organizationId: organizationStore.currentOrganizationId,
      useCache: false
    });
    const me = members.filter((member) => member.userId === authService.jwtUser?.sub)[0];
    const jwtOrg = authService.jwtUser[JWT_ORGANIZATION_CLAIM]?.find(
      (org) => org.id === organizationStore.currentOrganizationId
    );

    if (!me) {
      throw new Error('User not found in Console');
    }

    if (!jwtOrg) {
      throw new Error('Organization not found in JWT');
    }

    const { roles } = jwtOrg;
    const { roles: consoleRoles } = me;

    // If the roles do not match the ones in the JWT, the token is forcefully refreshed
    if (roles && consoleRoles && !isEqual([...roles].sort(), [...consoleRoles].sort())) {
      await this.#refreshTokenAndUpdateUser();
      return true;
    }

    return false;
  }

  /**
   * Refreshes the token and updates the user information.
   * This will trigger observers and update the UI.
   */
  #refreshTokenAndUpdateUser = async () => {
    await authService.refreshToken({ useCache: false });
    await authService.fetchJWTUser();
  };

  #onUserReady() {
    runInAction(() => {
      this.userId = authService.modelerUser?.id;
      this.userEmail = authService.modelerUser?.email;
      this.userName = authService.modelerUser?.name;
      this.userAuthProviderId = authService.jwtUser?.sub;
    });

    trackingService.registerUser({
      user: authService.jwtUser
    });
    tracingService.registerUser({
      modelerUser: authService.modelerUser
    });

    runInAction(() => {
      this.isAuthenticated = true;
    });
    window.__invitation = undefined;
  }
}

export default new UserStore();
