/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const VersionSelector = styled.div`
  display: inline-block;

  margin-left: var(--spacing-small);
  padding: 6px 10px;

  border-radius: 5px;
  line-height: 24px;

  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'not-allowed' : 'pointer')};
  ${({ $isReadOnly }) =>
    !$isReadOnly &&
    `
      &:hover {
        background: var(--silver-darken-87);
      }
  `};
`;

export const SelectionWithLabel = styled.div`
  display: inline-block;
  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'not-allowed' : 'pointer')};
`;

export const SelectionLabel = styled.label`
  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'not-allowed' : 'pointer')};
`;

export const Selection = styled.span`
  cursor: ${({ $isReadOnly }) => ($isReadOnly ? 'not-allowed' : 'pointer')};
  font-weight: 500;

  margin-left: 10px;
`;

export const Icon = styled.span`
  margin-left: var(--spacing-small);
`;

export const Dropdown = styled.div`
  padding: var(--spacing-small) var(--spacing-medium);
  width: 250px;
`;

export const Versions = styled.div`
  margin-top: var(--spacing-medium);
`;

export const Version = styled.div`
  height: ${({ $showClusters }) => ($showClusters ? '48' : '36')}px;
  cursor: pointer;
`;

export const VersionColumn = styled.div`
  display: inline-block;
`;

export const NoOfClusters = styled.div`
  color: var(--grey-base-40);
  font-weight: normal;
`;

export const Label = styled.label`
  cursor: pointer;
  & > :first-child {
    vertical-align: top;
    margin-top: -1px;
  }
`;

export const Link = styled.span`
  color: var(--cds-blue-60);
  cursor: pointer;
  text-decoration: underline;
`;

export const Collapse = styled.span`
  color: var(--cds-blue-60);
  cursor: pointer;
  margin-left: 4px;
  margin-right: 19px;
`;

export const Tag = styled.span`
  border-radius: 6px;
  background-color: #ffb359;
  padding: 1px 10px;
  font-weight: bold;
  margin-left: 10px;
  color: #000;
  font-size: 13px;
`;

export const Radio = styled.span`
  margin-right: 10px;
`;

export const RadioLabel = styled.span`
  font-weight: 500;
`;

export const ShowOlderVersions = styled.div`
  margin-top: 10px;
`;
