/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { v4 as uuidv4 } from 'uuid';

import { notificationStore } from 'components/NotificationSystem';
import pluralize from 'utils/pluralize';

export const showInvalidFilesError = (files) => {
  return notificationStore.error(
    {
      title: `Invalid ${pluralize('file', files.length)}`,
      content: (
        <>
          {files.map((file) => (
            <div key={uuidv4()}>{file.name}</div>
          ))}
        </>
      )
    },
    {
      shouldPersist: false,
      duration: 8000
    }
  );
};

export const showProgressIndicationInfo = (files, content) => {
  return notificationStore.info(
    {
      title: `Uploading ${pluralize('file', files.length)}`,
      content
    },
    {
      shouldPersist: true
    }
  );
};

export const showFileUploadFailedError = (filenames) => {
  return notificationStore.error(
    {
      title: `Failed to upload ${pluralize('file', filenames.length)}`,
      content: (
        <>
          {filenames.map((filename) => (
            <div key={uuidv4()}>{filename}</div>
          ))}
        </>
      )
    },
    {
      shouldPersist: false,
      duration: 8000
    }
  );
};

export const showC7FileUploadSuccess = (files) => {
  const length = files.length;

  return notificationStore.success(
    {
      title: `Upload successful`,
      content: (
        <>
          <span>
            <strong>{length}</strong> Camunda 7 {pluralize('resource', length)} {pluralize('has', length)} been
            imported.
            <br />
            <br />
            Some attributes might have changed.{' '}
            <a href="https://docs.camunda.io/docs/guides/migrating-from-camunda-7/" target="blank">
              Learn more
            </a>{' '}
            about migrating from Camunda 7.
          </span>
        </>
      )
    },
    {
      duration: 8000
    }
  );
};
