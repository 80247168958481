/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from 'services/Service';

class OpenAIService extends Service {
  constructor() {
    super();

    this.createChatCompletion = this.createChatCompletion.bind(this);
  }

  createChatCompletion(payload) {
    return this.post(`/openai/v1/chat/completions`, payload);
  }
}

export default new OpenAIService();
