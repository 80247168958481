/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import DMNModdle from 'dmn-moddle';

export const isBusinessRuleTask = (element) => {
  return is(element, 'bpmn:BusinessRuleTask');
};

const isDecision = (element) => {
  return is(element, 'dmn:Decision');
};

const getCalledDecision = (extensionElements) => {
  return extensionElements?.get('values').filter((elem) => {
    return elem.$type === 'zeebe:CalledDecision' || elem.$type === 'zeebe:calledDecision';
  })[0];
};

export const getCalledDecisionId = (extensionElements) => {
  const calledElement = getCalledDecision(extensionElements);
  if (calledElement?.decisionId) {
    return calledElement?.decisionId?.trim();
  }
};

export const detectDecisions = async (xml) => {
  const decisions = [];
  try {
    if (xml) {
      const dmnModdle = new DMNModdle();
      const { elementsById } = await dmnModdle.fromXML(xml);
      Object.values(elementsById)?.forEach((el) => {
        if (isDecision(el)) {
          if (el?.id) {
            decisions.push({ id: el.id, name: el?.name || '' });
          }
        }
      });
    }
  } catch (e) {
    console.info(e);
  }
  return decisions;
};
