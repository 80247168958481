/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import Notification from './Notification';
import { notificationStore } from './';
import * as Styled from './Notifications.styled';

function Notifications() {
  const { notifications } = notificationStore;

  if (notifications.length) {
    return (
      <Styled.Notifications>
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            id={notification.id}
            isClosing={notification.__close}
            {...notification.props}
          />
        ))}
      </Styled.Notifications>
    );
  }

  return null;
}

export default observer(Notifications);
