/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useCallback, useEffect } from 'react';

export default function ClickOutsideListener({ insideRef, onClickOutside, outsideRef }) {
  const onClick = useCallback(
    (event) => {
      const { target } = event;

      if (!insideRef.current?.contains(target)) {
        onClickOutside(event);
      }
    },
    [insideRef, onClickOutside]
  );

  useEffect(() => {
    const element = outsideRef ? outsideRef.current : document;

    element.addEventListener('click', onClick, { capture: true });

    return () => element.removeEventListener('click', onClick, { capture: true });
  }, [insideRef, onClick, outsideRef]);

  return null;
}
