/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, makeObservable, observable, runInAction } from 'mobx';
import BPMNModdle from 'bpmn-moddle';

import { notificationStore, breadcrumbStore, projectStore, organizationStore } from 'stores';
import { processTemplateService, clusterService } from 'services';
import { BPMN, DEFAULT_ZEEBE_VERSION } from 'utils/constants';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';
import generateId from 'utils/generate-id';
import { getProcessIdFromContent, getDefinitions } from 'utils/web-modeler-diagram-parser';

class ProcessTemplateStore {
  loading = true;
  availableTemplates = [];

  constructor() {
    makeObservable(this, {
      loading: observable,
      availableTemplates: observable,
      reset: action,
      createDiagramFromSelectedTemplate: action,
      createDiagramFromBlankTemplate: action,
      fetchTemplates: action
    });
  }

  /**
   * Resets the store to its initial values
   */
  reset = () => {
    this.loading = true;
    this.availableTemplates = [];
  };

  /**
   * If a template is given, retrieves the information about the template and creates a new diagram using those. If template is not given, a blank diagram is created.
   * If a projectId is given, it creates the template in that project.
   * If a source is given, it is used to track where the diagram was created from.
   *
   * @param {String} template
   * @param {String} projectId
   * @param {String} source
   */
  async createDiagramFromSelectedTemplate(template, projectId, source = 'template-dialog') {
    let diagram;

    if (template) {
      try {
        const { id: processTemplateId, name: templateName, content } = template;
        const initialProcessId = await getProcessIdFromContent(content);
        const contentWithProcessIdsReplaced = await this.appendUniqueIdToProcessIds(content);
        const replacedProcessId = await getProcessIdFromContent(contentWithProcessIdsReplaced);
        const newFileContent = {
          processId: replacedProcessId,
          processTemplateId,
          name: templateName,
          content: await this.setLatestExecutionPlatformVersion(contentWithProcessIdsReplaced)
        };

        const { currentOrganizationId, isUsingTrial, isTrialExpired } = organizationStore;

        if (projectId) {
          await projectStore.initProject(projectId);
        }

        await clusterService.createClusterIfNecessary({
          organizationId: currentOrganizationId,
          isUsingTrial,
          isTrialExpired
        });

        diagram = await projectStore.createFile({
          type: BPMN,
          file: newFileContent,
          source: source,
          processTemplateUsed: initialProcessId
        });
      } catch (e) {
        notificationStore.showError(
          'Yikes! Could not fetch the selected template information. Please try again later.'
        );
      }
    } else {
      try {
        diagram = await projectStore.createFile({ type: BPMN });
      } catch (e) {
        notificationStore.showError('Yikes! Could not create the diagram. Please try again later.');
      }
    }

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  }

  /**
   * Creates a empty diagram using a blank template.
   */
  async createDiagramFromBlankTemplate() {
    return this.createDiagramFromSelectedTemplate();
  }

  /**
   * Fetches available templates and returns a normalized dataset.
   */
  async fetchTemplates() {
    try {
      const availableTemplates = await processTemplateService.fetchAvailableTemplates();

      runInAction(() => {
        this.loading = true;

        const templatesWithFeaturedOrder = availableTemplates.filter((template) => template.featuredOrder);
        const templatesWithoutFeaturedOrder = availableTemplates.filter((template) => !template.featuredOrder);
        this.availableTemplates =
          templatesWithFeaturedOrder
            .sort(this.#sortByFeaturedOrder)
            .concat(templatesWithoutFeaturedOrder.sort(this.#sortByName))
            .map(this.#mapTemplateEntity) ?? [];
      });
    } catch (e) {
      notificationStore.showError('Yikes! Could not fetch the templates information. Please try again later.');
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  #sortByFeaturedOrder(a, b) {
    return a.featuredOrder - b.featuredOrder;
  }

  #sortByName(a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  #mapTemplateEntity(template) {
    const formatDate = (time) => new Date(time).toLocaleDateString();

    const { created } = template;

    return {
      ...template,
      createdDate: formatDate(created)
    };
  }

  async appendUniqueIdToProcessIds(xml) {
    const definitions = await getDefinitions(xml);
    const processes = definitions?.rootElements?.filter((element) => element.$type === 'bpmn:Process');
    processes?.forEach((process) => {
      process.set('id', generateId({ prefix: `${process.get('id')}-` }));
    });
    const { xml: outputXML } = await new BPMNModdle().toXML(definitions);
    return outputXML;
  }

  async setLatestExecutionPlatformVersion(xml) {
    const definitions = await getDefinitions(xml);
    definitions.set('modeler:executionPlatform', 'Camunda Cloud');
    definitions.set('modeler:executionPlatformVersion', DEFAULT_ZEEBE_VERSION);
    const { xml: outputXML } = await new BPMNModdle().toXML(definitions);
    return outputXML;
  }
}

export default new ProcessTemplateStore();
