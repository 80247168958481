/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { DUMMY_ORGANIZATION_ID } from 'utils/constants';

import BaseOrganizationStore from './OrganizationStore.common';

class OrganizationStore extends BaseOrganizationStore {
  hasRevocableRoles = false;

  constructor() {
    super();
  }

  get currentOrganizationId() {
    return DUMMY_ORGANIZATION_ID;
  }

  isAllowedHostnameForInvites() {
    return true;
  }

  compareAndSwitchOrganization = () => {};
}

export default new OrganizationStore();
