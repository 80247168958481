/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { InformationFilled } from '@carbon/icons-react';

import { Deploy, Play } from 'icons';
import { getUrlParam } from 'utils/url-params';
import { clustersStore, currentDiagramStore } from 'stores';
import { Dropdown } from 'components';
import { Link, WithExternalLinkIcon } from 'primitives';
import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import {
  isDeployedModalVisibleStore,
  isExecuteModalVisibleStore,
  isLinkingImprovementsModalVisibleStore
} from 'App/Pages/Diagram/stores';

import ClusterUpdateNotification from './ClusterUpdateNotification';
import ExecuteModal from './executeModal';
import RbaResourceDeployedModal from './rbaResourceDeployedModal';
import * as Styled from './DeploymentActions.styled';
import LinkingImprovementsModal from './linkingImprovementsModal';
import ClusterCreationModal, { clusterCreationModalStore } from './clusterCreationModal';

const DeploymentActions = ({ hasProcessDetailsDropdown, hasLegacyClusterCreationJourney = true }) => {
  const { isDeployOrStartInstanceButtonPresent, isAllowedToStartInstance } = deploymentPermissionsStore;
  const { latestDeployedCluster } = deploymentStore;

  const { isVisible: isExecuteModalVisible, setIsVisible: setIsExecuteModalVisible } = isExecuteModalVisibleStore;
  const { open: openClusterCreationModal } = clusterCreationModalStore;

  const {
    isVisible: isDeployedModalVisible,
    reset: resetDeployedModal,
    hide: hideDeployedModal,
    processId,
    decisionIds
  } = isDeployedModalVisibleStore;

  const { availableClusters, watchClustersAndUpdate } = clustersStore;
  const hasAvailableClusters = availableClusters?.length > 0;

  const {
    isVisible: isImprovementsModalVisible,
    reset: resetImprovementsModal,
    hide: hideImprovementsModal,
    show: showImprovementsModal,
    selectedClusterVersion
  } = isLinkingImprovementsModalVisibleStore;

  const { diagram } = currentDiagramStore.state;

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setIsExecuteModalVisible(getUrlParam('modal') || false);

    return () => {
      clustersStore.clearWatchClustersTimer();
    };
  }, []);

  useEffect(() => {
    if (diagram) {
      deploymentStore.init?.(diagram.id, diagram.processId);
      deploymentPermissionsStore.init();
    }

    return () => {
      deploymentStore.reset?.();
      deploymentPermissionsStore.reset();
      hideImprovementsModal();
    };
  }, [diagram]);

  const shouldRenderClusterCreationJourneyDialog = !hasLegacyClusterCreationJourney && !hasAvailableClusters;
  let nextCreationStep = useRef(null);

  const onDeploy = () => {
    if (shouldRenderClusterCreationJourneyDialog) {
      nextCreationStep.current = 'deploy';
      openClusterCreationModal();
    } else {
      setIsExecuteModalVisible('deploy');
    }
  };
  const onExecute = () => {
    if (shouldRenderClusterCreationJourneyDialog) {
      nextCreationStep.current = 'execute';
      openClusterCreationModal();
    } else {
      setIsExecuteModalVisible('execute');
    }
  };

  const deployButton = (
    <Styled.PrimaryButton data-test="deploy-button" title="Deploys the current diagram" size="small" onClick={onDeploy}>
      <Deploy />
      Deploy
    </Styled.PrimaryButton>
  );

  const startInstanceDropdownButton = hasProcessDetailsDropdown ? (
    <>
      <Styled.StartInstanceButton
        data-test="start-instance-dropdown"
        size="small"
        title="Deploys the current process (if not already deployed) and starts a new instance"
        onMainButtonClick={onExecute}
        onDropdownClick={(evt) => setAnchorEl(evt.currentTarget)}
      >
        <Play /> Run
      </Styled.StartInstanceButton>

      <Dropdown size="small" open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {!latestDeployedCluster ? (
          <Styled.StartInstanceMessage role="menuitem">
            <InformationFilled /> Start an instance to access process details
          </Styled.StartInstanceMessage>
        ) : (
          <>
            <Styled.DropdownListGroup>
              <Link href={deploymentStore.operateUrl} target="_blank" rel="noreferrer">
                <Styled.DropdownListItem aria-label="View process instance button">
                  <WithExternalLinkIcon label="View process instances" />
                </Styled.DropdownListItem>
              </Link>
            </Styled.DropdownListGroup>

            {latestDeployedCluster && (
              <Styled.DropdownListGroup>
                <Styled.ClusterDropdownListItem disabled>{latestDeployedCluster.name}</Styled.ClusterDropdownListItem>
              </Styled.DropdownListGroup>
            )}
          </>
        )}
      </Dropdown>
    </>
  ) : (
    <Styled.PrimaryButton
      data-test="start-instance-button"
      size="small"
      onClick={onExecute}
      title="Deploys the current process (if not already deployed) and starts a new instance"
    >
      <Play />
      Run
    </Styled.PrimaryButton>
  );

  const executeModal = (
    <ExecuteModal
      open={Boolean(isExecuteModalVisible)}
      onClose={({ hasBeenExecutedOnClusterLT8_4 } = {}) => {
        setIsExecuteModalVisible(false);
        if (hasBeenExecutedOnClusterLT8_4) {
          // Introducing a delay between transitioning from ExecuteModal to LinkingImprovementsModal
          setTimeout(() => {
            showImprovementsModal();
          }, 800);
        }
      }}
      deployOnly={isExecuteModalVisible === 'deploy'}
    />
  );

  const clusterCreationModal = (
    <ClusterCreationModal
      onClose={() => {
        clustersStore.clearWatchClustersTimer();
        nextCreationStep.current = null;
      }}
      onSubmit={async () => {
        const updated = await watchClustersAndUpdate();
        if (updated) {
          setIsExecuteModalVisible(nextCreationStep.current);
          nextCreationStep.current = null;
        }
      }}
    />
  );

  return (
    <Styled.DeploymentActions>
      {isDeployOrStartInstanceButtonPresent && (
        <>
          {isAllowedToStartInstance ? (
            <>
              {deployButton}{' '}
              <ClusterUpdateNotification isModalOpen={isExecuteModalVisible}>
                {startInstanceDropdownButton}
              </ClusterUpdateNotification>
            </>
          ) : (
            <ClusterUpdateNotification isModalOpen={isExecuteModalVisible}>{deployButton}</ClusterUpdateNotification>
          )}

          {shouldRenderClusterCreationJourneyDialog ? clusterCreationModal : executeModal}

          <LinkingImprovementsModal
            open={isImprovementsModalVisible}
            onClose={() => {
              hideImprovementsModal();
              resetImprovementsModal();
            }}
            selectedClusterVersion={selectedClusterVersion}
          />
          <RbaResourceDeployedModal
            open={isDeployedModalVisible}
            onClose={() => {
              hideDeployedModal();
              resetDeployedModal();
            }}
            processId={processId}
            decisionIds={decisionIds}
          />
        </>
      )}
    </Styled.DeploymentActions>
  );
};

export default observer(DeploymentActions);
