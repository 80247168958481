/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import 'styles/_carbon.scss';

import { createGlobalStyle } from 'styled-components';

import buildPath from 'utils/build-path';

export default createGlobalStyle`
  :root {
    --cds-blue-10: #edf5ff;
    --cds-blue-20: #d0e2ff;
    --cds-blue-30: #a6c8ff;
    --cds-blue-40: #78a9ff;
    --cds-blue-50: #4589ff;
    --cds-blue-60: #0f62fe;
    --cds-blue-70: #0043ce;
    --cds-blue-80: #002d9c;
    --cds-blue-90: #001d6c;
    --cds-blue-100: #001141;

    --cds-support-success: #24a148;
    --cds-support-error: #da1e28;
    --cds-support-warning: #f1c21b;

    --cds-icon-interactive: #0f62fe;
    --cds-icon-secondary: #525252;

    --cds-border-subtle-01: #e0e0e0;

    --silver-lighten-99: hsl(0, 0%, 99%);
    --silver-base-98: hsl(0, 0%, 98%);
    --silver-base-97: hsl(0, 0%, 97%);
    --silver-darken-94: hsl(0, 0%, 94%);
    --silver-darken-87: hsl(0, 0%, 87%);
    --silver-darken-80: hsl(0, 0%, 80%);
    --silver-darken-89: hsl(0, 0%, 89%);

    --grey-lighten-60: hsl(0, 0%, 60%);
    --grey-lighten-56: hsl(0, 0%, 56%);
    --grey-lighten-50: hsl(0, 0%, 50%);
    --grey-base-40: hsl(0, 0%, 40%);
    --grey-darken-36: hsl(0, 0%, 36%);
    --grey-darken-33: hsl(0, 0%, 33%);
    --grey-darken-30: hsl(0, 0%, 30%);
    --grey-darken-23: hsl(0, 0%, 23%);
    --grey-darken-13: hsl(0, 0%, 13%);

    --red-lighten-85: hsl(360, 100%, 85%);
    --red-base-62: hsl(360, 100%, 62%);
    --red-darken-52: hsl(360, 100%, 52%);
    --red-darken-42: hsl(360, 100%, 42%);
    --red-darken-38: hsl(360, 100%, 38%);
    --red-darken-26: hsl(360, 100%, 26%);
    --red-darken-18: hsl(360, 100%, 18%);

    --orange-base-60: hsl(34, 100%, 60%);

    --green-base-44: hsl(105, 86%, 44%);

    --spacing-tiny: 5px;
    --spacing-small: 10px;
    --spacing-medium: 20px;
    --spacing-large: 40px;
    --spacing-huge: 60px;

    --cm-color-black-primary-text: #161616;
    --cm-color-black-secondary-text: #525252;
    --cm-color-black-inactive-text: rgba(22, 22, 22, 0.25);
    --cm-color-blue-base: #4d90ff;
    --cm-color-green-base: #10d070;
    --cm-color-red-base: #ff3d3d;
    --cm-color-orange-base: #ffa533;
    --cm-color-orange-empty-state: #ff8201;
    --cm-color-white-base: #ffffff;
    --cm-color-white-base-rgb: 255, 255, 255;
    --cm-color-black-base-rgb: 0, 0, 0;
    --cm-color-ui-light1: #f2f3f5;
    --cm-color-ui-light2: #f7f8fa;
    --cm-color-ui-light3: #b0bac7;
    --cm-color-ui-light4: #fdfdfe;
    --cm-color-ui-light5: #d8dce3;
    --cm-color-ui-light5-rgb: 216, 220, 227;
    --cm-color-ui-light6: #62626e;
    --cm-color-ui-light6-rgb: 98, 98, 110;
    --cm-color-ui-dark1: #1c1f23;
    --cm-color-ui-dark2: #313238;
    --cm-color-ui-dark3: #393a41;
    --cm-color-ui-dark4: #45464e;
    --cm-color-ui-dark5: #5b5e63;
    --cm-color-ui-dark6: #6d7076;
    --cm-color-focus-outer-neutral: #8cb7ff;
    --cm-color-focus-inner-neutral: #c8e1ff;
    --cm-color-focus-inner-neutral2: #2b7bff;
    --cm-color-focus-outer-danger: #ffb3b3;
    --cm-color-focus-inner-danger: #ffe0e0;
    --cm-color-button-light1: #cdd4df;
    --cm-color-button-light2: #9ea9b7;
    --cm-color-button-light3: #88889a;
    --cm-color-button-light4: #f1f2f5;
    --cm-color-button-light5: #e7e9ee;
    --cm-color-button-light6: #d3d6e0;
    --cm-color-button-blue1: #a2c5ff;
    --cm-color-button-blue2: #80b0ff;
    --cm-color-button-blue3: #3c85ff;
    --cm-color-button-blue4: #1a70ff;
    --cm-color-button-blue5: #005df7;
    --cm-color-button-red1: #ffcccc;
    --cm-color-button-red2: #ffb3b3;
    --cm-color-button-red3: #ff0a0a;
    --cm-color-button-red4: #d60000;
    --cm-color-button-red5: #c20000;
    --cm-color-link: hsl(218, 58%, 49%);
    --cm-color-link-hover: hsl(218, 58%, 55%);
    --cm-color-link-active: hsl(218, 58%, 39%);
    --cm-color-link-dark: hsl(213, 100%, 93%);
    --cm-color-link-hover-dark: hsl(213, 100%, 89%);
    --cm-color-link-active-dark: hsl(214, 100%, 96%);

    // From desktop modeler
    --blue-205-100-50: hsl(205, 100%, 50%);
    --blue-205-100-95: hsl(205, 100%, 95%);

    // z-index
    --z-notification: 9100; // must be above the z-index of the tooltips
    --z-snackbar: 9100; // must be above the z-index of the tooltips
    --z-tooltip: 9000; // must be above the z-index of the dropdowns, and above the z-index of the carbon modal (9000)
    --z-dropdown: 8200; // must be above the z-index of the dialogs
    --z-dialog: 8100; // must be above the z-index of the top bar (8001)
    --z-feel-editor: 8099; // must be above top bar (8001) but below the dialogs
    --z-popup-menu: 8099; // must be must be above top bar (8001) but below the dialogs
    --z-resizable-panel-handle: 8000; // must be below dialogs
    --z-tooltip-guide: 8000;
    --z-xml-editor: 8000; // must be below the z-index of the top bar
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'), url(${buildPath('/fonts/ibm-plex-sans-bold.woff2')}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url(${buildPath('/fonts/ibm-plex-sans-medium.woff2')}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('IBM Plex Sans'), local('IBMPlexSans'), url(${buildPath('/fonts/ibm-plex-sans-regular.woff2')}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('IBM Plex Mono'), local('IBMPlexMono'), url(${buildPath('/fonts/ibm-plex-mono-regular.woff2')}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


  * {
    box-sizing: border-box;
  }

  strong, b {
    font-weight: bold;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    color: var(--grey-darken-33);
    margin: 0;
    font: 400 14px/1.6 "IBM Plex Sans", BlinkMacSystemFont, "Segoe UI", Arial, sans-serif;
    background-color: var(--silver-base-97);
    overflow: hidden;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .clickable {
    cursor: pointer;
  }
`;
