/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';

export default function useFocusTrap({ open, ref, selectorPrimaryFocus }) {
  useEffect(() => {
    if (open) {
      if (selectorPrimaryFocus) {
        const element = ref.current.querySelector(selectorPrimaryFocus);

        if (element) {
          element.focus();

          return;
        }
      }

      const elements = getFocusable(ref);

      if (elements.length > 0) {
        elements[0].focus();
      }
    }
  }, [open, selectorPrimaryFocus]);
}

const focusable = ['select', 'input', 'textarea', 'button', 'a'];

export const getFocusable = (ref) => {
  if (ref.current) {
    return Array.from(ref.current.querySelectorAll(focusable.join(', '))).filter((element) => !element.disabled);
  }

  return [];
};
