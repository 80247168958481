/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import buildPath from 'utils/build-path';

const MONACO_LOADER_CONFIG = { paths: { vs: buildPath('/min/vs') } };

export { MONACO_LOADER_CONFIG };
