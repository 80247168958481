/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { v4 } from 'uuid';

import { transitionDuration } from './Notification.styled';

// TimeToLive in ms
const NOTIFICATION_TTL = 5000;

class NotificationStore {
  infoCollection = new Map();
  warningCollection = new Map();
  errorCollection = new Map();
  successCollection = new Map();

  #activeNotification = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.infoCollection = new Map();
    this.warningCollection = new Map();
    this.errorCollection = new Map();
    this.successCollection = new Map();
    this.#activeNotification = null;
  }

  showSuccess(message) {
    return this.success({ title: 'Success', content: message });
  }

  showError(message) {
    return this.error({ title: 'Error', content: message });
  }

  /**
   * Push an info notification to the store
   * @param props {object} - The notification parameters (same as the carbon API, plus content for string messages)
   * @param options {object} - The notification options (shouldPersist, replacesGroup, duration)
   * @returns {{__dispose: boolean, options: Object, __close: boolean, id: string, __timestamp: number, props: Object}}
   */
  info(props, options = { shouldPersist: false, replacesGroup: false, duration: NOTIFICATION_TTL }) {
    return this.#pushNotification({ ...props, kind: 'info' }, options);
  }

  /**
   * Push a warning notification to the store
   * @param props {object} - The notification parameters (same as the carbon API, plus content for string messages)
   * @param options {object} - The notification options (shouldPersist, replacesGroup, duration)
   * @returns {{__dispose: boolean, options: Object, __close: boolean, id: string, __timestamp: number, props: Object}}
   */
  warning(props, options = { shouldPersist: false, replacesGroup: false, duration: NOTIFICATION_TTL }) {
    return this.#pushNotification({ ...props, kind: 'warning' }, options);
  }

  /**
   * Push an error notification to the store
   * @param props {object} - The notification parameters (same as the carbon API, plus content for string messages)
   * @param options {object} - The notification options (shouldPersist, replacesGroup, duration)
   * @returns {{__dispose: boolean, options: Object, __close: boolean, id: string, __timestamp: number, props: Object}}
   */
  error(props, options = { shouldPersist: false, replacesGroup: false, duration: NOTIFICATION_TTL }) {
    return this.#pushNotification({ ...props, kind: 'error' }, options);
  }

  /**
   * Push a success notification to the store
   * @param props {object} - The notification parameters (same as the carbon API, plus content for string messages)
   * @param options {object} - The notification options (shouldPersist, replacesGroup, duration)
   * @returns {{__dispose: boolean, options: Object, __close: boolean, id: string, __timestamp: number, props: Object}}
   */
  success(props, options = { shouldPersist: false, replacesGroup: false, duration: NOTIFICATION_TTL }) {
    return this.#pushNotification({ ...props, kind: 'success' }, options);
  }

  setActiveNotification(notificationId) {
    this.#activeNotification = this.#getNotificationById(notificationId);
  }

  unsetActiveNotification() {
    if (this.#activeNotification?.__dispose) {
      this.disposeNotification(this.#activeNotification.id, this.#activeNotification.props.kind, true);
    }

    this.#activeNotification = null;
  }

  /**
   * Get all notifications
   * @returns {Array} - The notifications sorted by timestamp in descending order
   */
  get notifications() {
    return [
      ...this.infoCollection.values(),
      ...this.warningCollection.values(),
      ...this.errorCollection.values(),
      ...this.successCollection.values()
    ].sort((a, b) => b.__timestamp - a.__timestamp);
  }

  /**
   * Dispose a given notification if it's not the active one, otherwise mark it to be disposed after it's not active anymore
   * @param id {string} - The id of the notification to be disposed
   * @param kind {string} - The type of the notification to be disposed
   * @param shouldAnimate {boolean} - Whether the notification should be animated before being disposed
   */
  disposeNotification(id, kind, shouldAnimate = false) {
    // If the notification is the active one, mark it to be disposed after it's not active anymore
    if (id === this.#activeNotification?.id && !this.#activeNotification?.__dispose) {
      this.#activeNotification.__dispose = true;
      return;
    }

    // If the notification is the active one, and it's already marked to be disposed, unset the active notification
    if (id === this.#activeNotification?.id && this.#activeNotification?.__dispose) {
      this.#activeNotification = null;
    }

    const collection = this.#getCollectionByType(kind);

    if (!collection) {
      console.error(`Invalid notification kind '${kind}'`);
      return;
    }

    const notification = collection.get(id);

    if (!notification) {
      return;
    }

    if (shouldAnimate) {
      // Marking the notification to be removed
      notification.__close = true;

      const disposeTimeout = setTimeout(() => {
        this.#deleteFromCollection(collection, id);
        clearTimeout(disposeTimeout);
      }, transitionDuration);
    } else {
      this.#deleteFromCollection(collection, id);
    }
  }

  /**
   * Push a new notification to the store, disposing the old ones if necessary
   * @param props {object} - The notification parameters (same as the carbon API)
   * @param options {object} - The notification options
   * @returns {object} - The notification
   */
  #pushNotification(props, options) {
    const id = `notification-${v4()}`;
    const notification = {
      id,
      __dispose: false,
      __close: false,
      __timestamp: Date.now(),
      props,
      options
    };

    if (options.replacesGroup) {
      this.#disposeNotificationsByType(props.kind);
    }

    this.#getCollectionByType(props.kind).set(id, notification);

    if (!options.shouldPersist) {
      this.#setDisposeTimeout(notification);
    }

    return notification;
  }

  /**
   * Set a timeout to dispose a notification after its duration
   * @param notification {object} - The notification to be disposed
   */
  #setDisposeTimeout(notification) {
    if (!notification) {
      return;
    }

    // We get the notification from the collection, because it might have been disposed in the meantime
    const existingNotification = this.#getNotificationById(notification.id);

    if (!existingNotification) {
      return;
    }

    const timeout = setTimeout(() => {
      this.disposeNotification(existingNotification.id, existingNotification.props.kind, true);
      clearTimeout(timeout);
    }, existingNotification.options.duration || NOTIFICATION_TTL);
  }

  /**
   * Delete a notification from a collection
   * @param collection {Map} - The collection from which the notification should be deleted
   * @param id {string} - The id of the notification to be deleted
   */
  #deleteFromCollection(collection, id) {
    runInAction(() => {
      collection.delete(id);
    });
  }

  /**
   * Get notification by id
   * @param id {string} - The id of the notification to be retrieved
   * @returns {object} - The notification with the given id
   */
  #getNotificationById(id) {
    return this.notifications.find((n) => n.id === id);
  }

  /**
   * Dispose all notifications of a specific type, except the active one and the ones that should persist
   * @param kind {string} - The type of the notifications to be disposed
   */
  #disposeNotificationsByType(kind) {
    const collection = this.#getCollectionByType(kind);

    if (!collection) {
      return;
    }

    for (let k of collection.keys()) {
      const notification = collection.get(k);
      const isActive = notification.id === this.#activeNotification?.id;

      if (isActive && !notification.options.shouldPersist) {
        // If the notification is the active one, mark it to be disposed after it's not active anymore
        notification.__dispose = true;
      } else if (!isActive && !notification.options.shouldPersist) {
        this.disposeNotification(notification.id, kind, true);
      }
    }
  }

  /**
   * Get collection by type
   * @param type {string} - The type of the collection to be retrieved
   * @returns {*} - The collection of the given type
   */
  #getCollectionByType(type) {
    return this[`${type}Collection`];
  }
}

export default new NotificationStore();
