/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import config from 'utils/config';

export const hasValidConfiguration = () =>
  config.modelerBasePath && config.modelerBasePath !== '/' && config.modelerBasePath !== '';

/**
 * Build a resource path, including the modeler base path if it exists
 * @param path {string} - The path to build
 * @returns {string} - The built path, including the modeler base path if it exists
 */
export default function buildPath(path) {
  if (hasValidConfiguration() && path.startsWith(config.modelerBasePath)) {
    return path;
  }

  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  return hasValidConfiguration() ? `${config.modelerBasePath}${normalizedPath}` : normalizedPath;
}
