/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, withRouter } from 'react-router-dom';
import { observer, Observer } from 'mobx-react';
import { PlayMode } from '@camunda/play';

import { useGate } from 'App/Statsig';
import { currentDiagramStore, diagramControlStore, notificationStore, realtimeCollaborationStore } from 'stores';
import { dedicatedModesStore, deploymentErrorsStore, publicationStore } from 'App/Pages/Diagram/stores';
import { connectorPollingService, fileService, projectService, Service, trackingService } from 'services';
import { DiscoverConnectorsModal, discoverConnectorsStore } from 'App/Pages/Diagram/DiscoverConnectorsModal';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import lintingStore from 'components/Modeler/LintingStore';
import { MONACO_LOADER_CONFIG } from 'components/CodeEditor/config';
import { Breadcrumb, ImportModal, TopBar } from 'components';
import { EmptyState } from 'primitives';
import { SadFace } from 'icons';
import getIdFromSlug from 'utils/getIdFromSlug';
import createPermission from 'utils/createPermission';
import buildSlug from 'utils/buildSlug';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { DMN } from 'utils/constants';
import { notificationStore as newNotificationStore } from 'components/NotificationSystem';
import useIsNewContextPadEnabled from 'experiments/new-context-pad/useIsNewContextPadEnabled';
import useAreAiFeaturesEnabled from 'experiments/new-context-pad/useAreAiFeaturesEnabled';

import Extensions from './Extensions';
import Header from './Header';
import ActionBar from './ActionBar';
import DmnViewer from './DmnViewer';
import BpmnViewer from './BpmnViewer';
import { errorPanelStore } from './ErrorPanel';
import * as Styled from './Diagram.styled';
import {
  BROWSE_MARKETPLACE_ONCLICK_EVENT,
  BROWSE_MARKETPLACE_ONDESTROY_EVENT,
  browseMarketplaceButtonEventManager
} from './BpmnJSExtensions/browseMarketplaceExtension';
import { hasBuiltInTemplates } from './BpmnJSExtensions/connectorsExtension';
import { InvalidDiagramDialog, invalidDiagramDialogStore } from './InvalidDiagramDialog';

export const Diagram = (props) => {
  const { value: areDiscoverableAndPrettyVariablesEnabled } = useGate('discoverable-pretty-variables');
  const { value: areExplanatoryNotificationsEnabled } = useGate('explanatory-notifications');

  const { diagram, project, isFetching } = currentDiagramStore.state;
  const { modeler, isBPMN, lastReloadedContent, isNotFound, hasBeenValidated, isValid } = currentDiagramStore;
  const { isEditorOpen } = XMLEditorStore;

  const permission = createPermission(project?.permissionAccess);
  const { isImplementMode } = dedicatedModesStore;
  const [isDiscoverConnectorsModalOpen, setIsDiscoverConnectorsModalOpen] = useState(false);
  const location = useLocation();

  const service = useMemo(() => new Service(), []);

  const handlePostRequest = useCallback(service.post.bind(service), [service]);
  const displayInfoNotification = useCallback(newNotificationStore.info.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displayErrorNotification = useCallback(newNotificationStore.error.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displaySuccessNotification = useCallback(newNotificationStore.success.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const displayWarningNotification = useCallback(newNotificationStore.warning.bind(newNotificationStore), [
    newNotificationStore
  ]);
  const fetchFileById = useCallback(fileService.fetchById.bind(fileService), [fileService]);
  const convertLinkedFormsToEmbeddedForms = useCallback(
    fileService.convertLinkedFormsToEmbeddedForms.bind(fileService),
    [fileService]
  );
  const fetchFilesByDecisionId = useCallback(projectService.fetchFilesByDecisionId.bind(projectService), [
    projectService
  ]);
  const fetchFilesByProcessId = useCallback(projectService.fetchFilesByProcessId.bind(projectService), [
    projectService
  ]);
  const mixpanelTrack = useCallback(trackingService.track.bind(trackingService), [trackingService]);

  connectorPollingService.pollInboundConnectorStatus({
    isImplementMode,
    processId: currentDiagramStore.state?.diagram?.processId,
    interval: 5000
  });

  const [discoverConnectorsModalActionSource, setDiscoverConnectorsModalActionSource] = useState(null);

  const init = async () => {
    await currentDiagramStore.fetchDiagramById(getIdFromSlug(props.match.params.slug));
    dedicatedModesStore.init();
    XMLEditorStore.init();
    consolidateUrlSlug();

    browseMarketplaceButtonEventManager.subscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
  };

  const reset = () => {
    currentDiagramStore.reset();
    diagramControlStore.reset();
    realtimeCollaborationStore.reset();
    deploymentErrorsStore.reset();
    dedicatedModesStore.reset();
    publicationStore.reset();
    formLinkStore.reset();
    XMLEditorStore.dispose();
    invalidDiagramDialogStore.reset();

    browseMarketplaceButtonEventManager.unsubscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
    browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONDESTROY_EVENT);
  };

  const consolidateUrlSlug = () => {
    const url = document.location.href;
    const { diagram } = currentDiagramStore.state;

    if (!diagram) {
      return;
    }

    // Prevents faulty URL parsing if the user leaves the diagram
    // page fastly
    if (!url.includes('/diagrams/')) {
      return;
    }

    const slug = url.split('--')[1] ? url.split('--')[1].split('?')[0] : undefined;

    const splitStringSeparator = url.includes('/diagrams/xml/') ? '/diagrams/xml/' : '/diagrams/';

    const currentSlug = url.split(splitStringSeparator)[1].split('?')[0];
    const newSlug = buildSlug(diagram.id, diagram.name);

    if (!slug || currentSlug !== newSlug) {
      // the state has to be changed via the `history` prop,
      // otherwise the change won't be observed by the React Router when listening on history
      props.history.replace(`${splitStringSeparator}${newSlug}${document.location.search}`);
    }
  };

  // TODO(marcello.barile): Use _search to prefill the search input field in the dialog, see https://github.com/camunda/web-modeler/issues/6428
  const onBrowseMarketplaceButtonClick = (actionSource, _search) => {
    setDiscoverConnectorsModalActionSource(actionSource);
    setIsDiscoverConnectorsModalOpen(true);
  };

  const onDiscoverMarketplaceClose = () => {
    setIsDiscoverConnectorsModalOpen(false);
  };

  const resetMarketplaceSelection = () => {
    discoverConnectorsStore.reset();
  };

  const onMarketplaceImportComplete = async () => {
    if (!project) {
      throw new Error('Project is not defined');
    }

    await currentDiagramStore.loadElementTemplates(project.id);
  };

  const getCanvasElements = () => {
    if (currentDiagramStore.modeler === null) {
      return [];
    }

    return currentDiagramStore.canvas?.getRootElement?.()?.children ?? [];
  };

  useEffect(() => {
    (async () => {
      await init();
    })();

    return () => {
      reset();
    };
  }, [props.match.params.slug]);

  useEffect(() => {
    if (!isValid || !currentDiagramStore.modeler || !isBPMN) {
      return;
    }

    const canvasElements = getCanvasElements();
    const onlyStartEvent = canvasElements.length === 1;

    if (onlyStartEvent) {
      currentDiagramStore.selection?.select(canvasElements[0]);
    }

    lintingStore.performLinting(currentDiagramStore.modeler, isImplementMode);
  }, [isBPMN, currentDiagramStore.modeler, isImplementMode, isValid]);

  useEffect(() => {
    const containsXML = location.pathname.includes('/xml/');

    if (isBPMN && modeler && containsXML && isImplementMode && permission.is(['WRITE', 'ADMIN'])) {
      XMLEditorStore.openEditor();
    }
  }, [isBPMN, modeler, isImplementMode]);

  useOpenXMLEditorForInvalidDiagram(hasBeenValidated, isValid, isEditorOpen);
  useCheckIfPublicAccessEnabled(isBPMN, modeler, lastReloadedContent);

  const newContextPadEnabled = useIsNewContextPadEnabled();
  const areAIFeaturesEnabled = useAreAiFeaturesEnabled();

  if (isFetching) {
    return <Styled.DiagramLoader />;
  }

  if (isNotFound) {
    return (
      <>
        <TopBar.Breadcrumbs>
          <Breadcrumb title="Home" variant="link" to="/" />
        </TopBar.Breadcrumbs>
        <EmptyState
          title="Diagram not found!"
          description="Sorry, the diagram might have been deleted."
          icon={<SadFace width="48" height="48" />}
        />
      </>
    );
  }

  const Viewer = diagram.type === DMN ? DmnViewer : BpmnViewer;
  const hasResourcesToImport = !!discoverConnectorsStore.resourcesToImport?.length;
  const hasConnectors = currentDiagramStore.state.templates.length > 0 || hasBuiltInTemplates;

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(diagram.name)}</title>
      </Helmet>
      <Header permission={permission} />

      <ActionBar shouldShowModesTooltip={getCanvasElements().length > 1} />

      {diagram.type !== DMN && dedicatedModesStore.isPlayMode ? (
        <Styled.Wrapper
          id={dedicatedModesStore.playModeAriaControl}
          data-test="zeebe-play"
          className="diagram-container"
          $showTemplates={currentDiagramStore.state.isShowingTemplate}
          $hasConnectors={hasConnectors}
          $isPlayMode
        >
          <Observer>
            {() => {
              return (
                <PlayMode
                  featureFlags={{
                    areExplanatoryNotificationsEnabled,
                    areDiscoverableAndPrettyVariablesEnabled,
                    newContextPadEnabled
                  }}
                  monacoLoaderConfig={MONACO_LOADER_CONFIG}
                  xml={currentDiagramStore.state.diagram.content}
                  switchToImplementMode={(shouldOpenOutputTab) => {
                    dedicatedModesStore.setViewModeIndex(
                      dedicatedModesStore.availableViewModes.find((mode) => mode.label === 'Implement').index
                    );

                    if (shouldOpenOutputTab) {
                      errorPanelStore.switchToOutputTab();
                    }
                  }}
                  onDeploymentError={(errorObject) => {
                    deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', errorObject);
                  }}
                  projectId={currentDiagramStore.state.project?.id}
                  processId={currentDiagramStore.state.diagram?.processId}
                  diagramId={currentDiagramStore.state.diagram?.id}
                  showNotification={notificationStore.showNotification}
                  displayNotification={{
                    info: displayInfoNotification,
                    warning: displayWarningNotification,
                    success: displaySuccessNotification,
                    error: displayErrorNotification
                  }}
                  mixpanelTrack={mixpanelTrack}
                  setExecutionPlatformVersion={currentDiagramStore.setExecutionPlatformVersion}
                  handlePostRequest={handlePostRequest}
                  fetchFilesByDecisionId={fetchFilesByDecisionId}
                  fetchFilesByProcessId={fetchFilesByProcessId}
                  fetchFileById={fetchFileById}
                  convertLinkedFormsToEmbeddedForms={convertLinkedFormsToEmbeddedForms}
                />
              );
            }}
          </Observer>
        </Styled.Wrapper>
      ) : (
        <>
          {isBPMN && config.marketplace?.enabled && (
            <>
              <ImportModal
                open={hasResourcesToImport}
                currentProject={project}
                showProjects={false}
                autoPublish
                resourcesToImportMetadata={discoverConnectorsStore.resourcesToImport}
                fetchDelay={2000}
                onClose={(evt) => {
                  resetMarketplaceSelection();

                  if (evt?.gotoMarketplace) {
                    setIsDiscoverConnectorsModalOpen(true);
                  }
                }}
                onPublishComplete={async () => {
                  await onMarketplaceImportComplete();
                  resetMarketplaceSelection();
                }}
              />

              <DiscoverConnectorsModal
                isOpen={isDiscoverConnectorsModalOpen}
                onClose={() => {
                  onDiscoverMarketplaceClose();
                }}
                actionSource={discoverConnectorsModalActionSource}
              />
            </>
          )}

          <Suspense fallback={<Styled.DiagramLoader />}>
            <Viewer
              permission={permission}
              experiments={{
                newContextPad: newContextPadEnabled,
                aiFeatures: areAIFeaturesEnabled
              }}
            />
          </Suspense>
          <Extensions />
        </>
      )}

      <InvalidDiagramDialog />
    </Fragment>
  );
};

export default withRouter(observer(Diagram));

const useCheckIfPublicAccessEnabled = (isBPMN, modeler, lastReloadedContent) => {
  /**
   * `lastReloadedContent` is needed in the dependency array
   * to update the `publicAccessEnabled` status, when changed during live collaboration
   */
  useEffect(() => {
    if (modeler && isBPMN) {
      publicationStore.checkIfPublicAccessEnabed(modeler);
    }
  }, [isBPMN, modeler, lastReloadedContent]);
};

/**
 * If the diagram is not valid, open the XML editor
 * and show the invalid diagram dialog. The dialog
 * will be shown only once per diagram, see the reset
 * method of its store.
 */
const useOpenXMLEditorForInvalidDiagram = (hasBeenValidated, isValid, isEditorOpen) => {
  useEffect(() => {
    if (hasBeenValidated && !isValid && !isEditorOpen) {
      invalidDiagramDialogStore.open();
      XMLEditorStore.openEditor();
    }
  }, [hasBeenValidated, isValid, isEditorOpen]);
};
