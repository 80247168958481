/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

/**
 * @type {import('bpmn-js/lib/model/Types').Element} Element
 * @type {import('bpmn-js/lib/model/Types').ModdleElement} ModdleElement
 * @type {import('bpmn-js/lib/Modeler').default} Modeler
 *
 * @type { {
 *   elementId: string;
 *   elementType: string;
 *   formId?: string;
 *   formKey?: string;
 *   formBody?: string;
 * } } ElementWithLinkedForm
 *
 * @type { {
 *   [id: string]: string;
 * } } UserTaskForms
 */

import Ids from 'ids';
import { isDefined } from 'min-dash';
import { isLabel } from 'diagram-js/lib/util/ModelUtil';
import { is, getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { getRootElement } from 'utils/web-modeler-diagram-parser/get-root-element';
import { getExtensionElementsList } from 'utils/web-modeler-diagram-parser/get-extension-elements-list';
import { isAnyInboundConnector } from 'utils/connectors/inbound-connectors';
import getInboundConnectorType from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/detectInboundConnector/getInboundConnectorType';
import { findExtensionElement, findExtensionElements } from 'utils/web-modeler-diagram-parser/extension-elements-util';

import PublicationElements from './PublicationElements';

export const isUserTask = (element) => is(element, 'bpmn:UserTask') && !isLabel(element);

export const isStartEvent = (element) => is(element, 'bpmn:StartEvent') && !isLabel(element);

export const isNoneStartEvent = (element) => {
  const businessObject = getBusinessObject(element);

  if (!businessObject) {
    return false;
  }

  const eventDefinitions = businessObject.get('eventDefinitions');
  return isStartEvent(businessObject) && !eventDefinitions?.length;
};

const isExecutableProcess = (element) => element && is(element, 'bpmn:Process') && element.isExecutable;

const getNumberOfExecutableParticipants = (collaborationElement) => {
  const participants = getBusinessObject(collaborationElement)?.get('participants');
  const participantsCount = participants?.length;

  if (!participantsCount) {
    return 0;
  }

  const executableProcesses = participants.filter((participant) => isExecutableProcess(participant.processRef));
  return executableProcesses.length;
};

/**
 * Checks if the given element is a parent of a process or a single executable participant.
 *
 * @param {Element} element
 *
 * @returns {boolean}
 */
export const isParentAProcessOrSingleExecutableParticipant = (element) => {
  const parent = element.parent;
  const parentBusinessObject = getBusinessObject(parent);

  const isParentExecutableProcess = isExecutableProcess(parentBusinessObject);
  if (isParentExecutableProcess) {
    return true;
  }

  const isParentParticipant = is(parent, 'bpmn:Participant');
  if (isParentParticipant) {
    const collaborationElement = parent.parent;

    const isParentProcessExecutable = isExecutableProcess(parentBusinessObject?.processRef);
    const hasOnlyOneExecutableParticipant = getNumberOfExecutableParticipants(collaborationElement) === 1;

    return isParentProcessExecutable && hasOnlyOneExecutableParticipant;
  }

  return false;
};

/**
 * Traverses a list of elements configured as publication elements and
 * checks if the given element is one of them.
 *
 * This is used to differentiate publication elements between SaaS and SM.
 */
export const isPublicationElement = (element) => {
  for (let i = 0; i < PublicationElements.length; i++) {
    if (is(element, PublicationElements[i])) {
      return true;
    }
  }

  return false;
};

/**
 * Checks if the given element is supported by the publication feature.
 *
 * @param {Element} element
 *
 * @returns {boolean}
 */
export const isPublicationSupportedByElement = (element) => {
  const elementType = getInboundConnectorType(element);

  return (
    isPublicationElement(element) &&
    !isAnyInboundConnector(elementType) &&
    isNoneStartEvent(element) &&
    isParentAProcessOrSingleExecutableParticipant(element)
  );
};

/**
 * Checks if the given element is supported by the form linking feature.
 *
 * @param {Element} element
 *
 * @returns {boolean}
 */
export const isFormLinkingSupportedByElement = (element) =>
  isPublicationSupportedByElement(element) || isUserTask(element);

function isValidFormKey(value) {
  const regex = /^camunda-forms:bpmn:(usertaskform)/i;

  return regex.test(value);
}

/**
 * Get all elements that have an embedded or linked form.
 *
 * @param {Modeler} modeler
 *
 * @returns { {
 *   startEvents: ElementWithLinkedForm[];
 *   userTasks: ElementWithLinkedForm[];
 * } }
 */
export function getElementsWithForm(modeler) {
  const elementRegistry = modeler.get('elementRegistry');

  const processes = findProcesses(elementRegistry.getAll());

  const userTaskForms = processes.reduce((userTaskForms, process) => {
    return {
      ...userTaskForms,
      ...findUserTaskForms(process)
    };
  }, {});

  const startEvents = elementRegistry.filter((element) => isStartEvent(element)),
    userTasks = elementRegistry.filter((element) => isUserTask(element));

  return {
    startEvents: findElementsWithForm(startEvents, userTaskForms),
    userTasks: findElementsWithForm(userTasks, userTaskForms)
  };
}

/**
 * NOTE: This file contains portions of code that were copied from the bpmn-js-properties-panel
 * library and adapted. This because the core library does not allow to have the form group for elements
 * that are not UserTask. (see #4583, and #6615)
 *
 * We have created an issue to clean this up, as soon as the core library allows us to do so.
 * See issue: #4737
 */

/**
 * generate a semantic id with given prefix
 */
export function nextId(prefix) {
  const ids = new Ids([32, 32, 1]);
  return ids.nextPrefixed(prefix);
}

export const FORM_KEY_PREFIX = 'camunda-forms:bpmn:';
export const USER_TASK_FORM_ID_PREFIX = 'UserTaskForm_';

export const FORM_TYPES = {
  CAMUNDA_FORM_EMBEDDED: 'camunda-form-embedded',
  CAMUNDA_FORM_LINKED: 'camunda-form-linked',
  CUSTOM_FORM: 'custom-form'
};

export const DEFAULT_FORM_TYPE = FORM_TYPES.CAMUNDA_FORM_LINKED;

export function createUserTaskFormId() {
  return nextId(USER_TASK_FORM_ID_PREFIX);
}

export function getFormDefinition(element) {
  const businessObject = getBusinessObject(element);

  const formDefinitions = getExtensionElementsList(businessObject, 'zeebe:FormDefinition');

  return formDefinitions[0];
}

export function getFormType(element) {
  const formDefinition = getFormDefinition(element);

  if (!formDefinition) {
    return;
  }

  const formId = formDefinition.get('formId'),
    formKey = formDefinition.get('formKey');

  if (isDefined(formId)) {
    return FORM_TYPES.CAMUNDA_FORM_LINKED;
  }

  if (isDefined(formKey)) {
    if (getUserTaskForm(element)) {
      return FORM_TYPES.CAMUNDA_FORM_EMBEDDED;
    }

    return FORM_TYPES.CUSTOM_FORM;
  }
}

export function getUserTaskForm(element, rootElement) {
  rootElement = rootElement || getRootElement(element);

  const formDefinition = getFormDefinition(element);

  if (!formDefinition) {
    return;
  }

  const formKey = formDefinition.get('formKey');

  const userTaskForms = getExtensionElementsList(rootElement, 'zeebe:UserTaskForm');

  return userTaskForms.find((userTaskForm) => {
    return userTaskFormIdToFormKey(userTaskForm.get('id')) === formKey;
  });
}

export function getUserTaskFormWithOptions(element, options = {}) {
  let { formKey, rootElement } = options;

  rootElement = rootElement || getRootElement(element);

  if (!formKey) {
    const formDefinition = getFormDefinition(element);

    if (!formDefinition) {
      return;
    }

    formKey = formDefinition.get('formKey');
  }

  const userTaskForms = getExtensionElementsList(rootElement, 'zeebe:UserTaskForm');

  return userTaskForms.find((userTaskForm) => {
    return userTaskFormIdToFormKey(userTaskForm.get('id')) === formKey;
  });
}

export function userTaskFormIdToFormKey(userTaskFormId) {
  return `${FORM_KEY_PREFIX}${userTaskFormId}`;
}

/**
 * Find all processes in the given elements.
 *
 * @param {Element[]} elements
 *
 * @returns {ModdleElement[]}
 */
function findProcesses(elements) {
  return elements.reduce((processes, element) => {
    if (is(element, 'bpmn:Participant')) {
      const process = getBusinessObject(element).get('processRef');

      if (process) {
        return [...processes, process];
      }
    }

    if (is(element, 'bpmn:Process')) {
      const process = getBusinessObject(element);

      return [...processes, process];
    }

    return processes;
  }, []);
}

/**
 * Find all user task forms in the given process.
 *
 * @param {ModdleElement} process
 *
 *  @returns {UserTaskForms}
 */
function findUserTaskForms(process) {
  return findExtensionElements(process, 'zeebe:UserTaskForm').reduce((userTaskForms, userTaskForm) => {
    const body = userTaskForm.get('body');

    if (body && body.trim().length) {
      return {
        ...userTaskForms,
        [userTaskForm.get('id')]: body
      };
    }

    return userTaskForms;
  }, {});
}

/**
 * Get all elements that have an embedded or linked form.
 *
 * @param {Element[]} elements
 * @param {UserTaskForms} userTaskForms
 *
 * @returns {ElementWithLinkedForm[]}
 */
const findElementsWithForm = (elements, userTaskForms) => {
  return elements.reduce((elementsOfTypeWithLinkedForm, element) => {
    const formDefinition = findExtensionElement(element, 'zeebe:FormDefinition');

    if (!formDefinition) {
      return elementsOfTypeWithLinkedForm;
    }

    const formId = formDefinition.get('formId'),
      formKey = formDefinition.get('formKey');

    const businessObject = getBusinessObject(element);

    if (formId && !userTaskForms[formId]) {
      return [
        ...elementsOfTypeWithLinkedForm,
        {
          elementId: businessObject.get('id'),
          elementType: businessObject.$type,
          formId
        }
      ];
    } else if (formKey && isValidFormKey(formKey)) {
      const userTaskFormId = formKey.split(':').pop();

      if (userTaskForms[userTaskFormId]) {
        return [
          ...elementsOfTypeWithLinkedForm,
          {
            elementId: businessObject.get('id'),
            elementType: businessObject.$type,
            formKey: userTaskFormId,
            formBody: userTaskForms[userTaskFormId]
          }
        ];
      }
    }

    return elementsOfTypeWithLinkedForm;
  }, []);
};
