/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { importConnectorTemplateService, importProcessTemplateService } from './index';

class ImportTemplateServiceHelper {
  defineService(location) {
    return location.pathname.includes('processes') ? importProcessTemplateService : importConnectorTemplateService;
  }
}

export default new ImportTemplateServiceHelper();
