/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable, action, computed, makeObservable } from 'mobx';

import BrowserDetector from 'utils/browser-detector';
import localStorage from 'utils/localstorage';

const DOWNTIME_START_DATE = new Date(Date.UTC(2023, 6, 27, 6, 0));
const DOWNTIME_END_DATE = new Date(Date.UTC(2023, 6, 27, 6, 30));
const DOWNTIME_STORAGE_KEY = `modeler.downtime.seen.${DOWNTIME_END_DATE.valueOf()}`;

class TopBannerStore {
  hasSeenOutdatedBrowserMessage = !!localStorage.getItem('hasSeenOutdatedBrowserMessage');

  constructor() {
    makeObservable(this, {
      hasSeenOutdatedBrowserMessage: observable,
      hideOutdatedBrowserMessage: action,
      isOutdatedBrowserMessageVisible: computed,
      hideDownTimeBanner: action,
      isDownTimeBannerVisible: computed
    });
  }

  hideOutdatedBrowserMessage = () => {
    localStorage.setItem('hasSeenOutdatedBrowserMessage', new Date());
    this.hasSeenOutdatedBrowserMessage = true;
  };

  hideDownTimeBanner = () => {
    localStorage.setItem(DOWNTIME_STORAGE_KEY, 'true');
  };

  get isOutdatedBrowserMessageVisible() {
    return !this.hasSeenOutdatedBrowserMessage && !new BrowserDetector().isSupported();
  }

  get isDownTimeBannerVisible() {
    return localStorage.getItem(DOWNTIME_STORAGE_KEY) !== 'true' && Date.now() < DOWNTIME_END_DATE.getTime();
  }

  get downTimeSchedule() {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };

    return {
      startTime: new Intl.DateTimeFormat('en', options).format(DOWNTIME_START_DATE),
      endTime: new Intl.DateTimeFormat('en', options).format(DOWNTIME_END_DATE),
      startDate: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).format(DOWNTIME_START_DATE),
      endDate: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).format(DOWNTIME_END_DATE)
    };
  }
}

export default new TopBannerStore();
