/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Notifications = styled.div`
  position: absolute;

  // Consider the space taken by the top bar
  top: 47px;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
`;
