/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import * as Styled from './styled';

const DROPDOWN_WIDTH = 200;

export default function SubDropdown({ children, ...props }) {
  const [wrapperRef, setWrapperRef] = useState(null);
  const [verticalAlignment, setVerticalAlignment] = useState('right');
  const [horizontalAlignment, setHorizontalAlignment] = useState('top');

  useEffect(() => {
    if (wrapperRef) {
      const { right: parentPositionRight, bottom: parentPositionBottom } = wrapperRef
        .closest('[data-dropdown]')
        .getBoundingClientRect();

      if (document.body.offsetWidth - parentPositionRight > DROPDOWN_WIDTH) {
        setVerticalAlignment('left');
      }

      if (parentPositionBottom + wrapperRef.offsetHeight > document.body.offsetHeight) {
        setHorizontalAlignment('bottom');
      }
    }
  }, [wrapperRef]);

  return (
    <Styled.SubDropdown
      $verticalAlignment={verticalAlignment}
      $horizontalAlignment={horizontalAlignment}
      ref={setWrapperRef}
      role="menu"
      {...props}
    >
      {children}
    </Styled.SubDropdown>
  );
}
