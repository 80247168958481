/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, TextArea, TextInput } from '@carbon/react';

import { Tooltip } from 'primitives';
import { ConnectorTemplate } from 'icons';
import { selectedElementStore } from 'App/Pages/Diagram/stores';
import { currentDiagramStore, notificationStore, projectStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import { CONNECTOR_TEMPLATE } from 'utils/constants';
import generateId from 'utils/generate-id';
import { trackingService } from 'services';
import buildPath from 'utils/build-path';

import * as Styled from './SaveConnectorConfig.styled';
import ConfigurationSavedNotification from './ConfigurationSavedNotification';
import { getConnectorTemplateFromElement, hydrateTemplateWithProperties, sanitizeTemplateName } from './utils';

const SaveConnectorConfig = ({ onActivate }) => {
  const { selectedElement, needsRefresh } = selectedElementStore;
  const { modeler, state: diagramStoreState } = currentDiagramStore;
  const { project } = diagramStoreState;
  const [showDialog, setShowDialog] = useState(false);
  const [template, setTemplate] = useState(null);
  const [defaultTemplateName, setDefaultTemplateName] = useState('');
  const [defaultTemplateDescription, setDefaultTemplateDescription] = useState('');

  let newTemplateName;
  let newTemplateDescription;

  // Set the template from the selected element
  useEffect(() => {
    const hasMandatoryElements = selectedElement && modeler;
    if (hasMandatoryElements || (hasMandatoryElements && needsRefresh)) {
      setTemplate(
        getConnectorTemplateFromElement({
          modeler,
          element: selectedElement
        })
      );

      if (needsRefresh) {
        selectedElementStore.setNeedsRefresh(false);
      }
    } else {
      setTemplate(null);
    }
  }, [selectedElement, modeler, needsRefresh]);

  // Set the default template name and description
  useEffect(() => {
    if (template) {
      setDefaultTemplateName(`${sanitizeTemplateName(template.name)} - Copy`);
      setDefaultTemplateDescription(template.description);

      onActivate(true);
    } else {
      onActivate(false);
    }
  }, [template]);

  const [dialogSize, setDialogSize] = useState('xs');
  const smallHeightBreakpoint = 1000;

  // Set the dialog size based on the screen height,
  // for small screens the dialog should be small and not xsmall
  // to avoid the content being cut off
  useEffect(() => {
    // TODO(philippfromme): should be part of a reusable modal component
    const updateDimension = () => {
      const newDimension = getCurrentDimension();
      const shouldBeSmall = newDimension.height < smallHeightBreakpoint;
      setDialogSize(shouldBeSmall ? 'sm' : 'xs');
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  if (!template) {
    return null;
  }

  const openDialog = () => {
    setShowDialog(true);

    const { id: fileId, type } = currentDiagramStore.state.diagram;
    trackingService.trackSaveAsConnectorTemplate({
      connectorId: template.id,
      fileId,
      type
    });
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  /**
   * Saves the connector template as a new file in the project.
   */
  const saveAs = async () => {
    try {
      template.id = `ConnectorTemplate_${generateId()}`;
      template.name = newTemplateName?.trim() || defaultTemplateName;
      template.description = newTemplateDescription?.trim() || defaultTemplateDescription;

      hydrateTemplateWithProperties({ template, element: selectedElement });

      const payload = {
        name: template.name,
        projectId: project.id,
        content: JSON.stringify(template, undefined, 2)
      };

      const file = await projectStore.createFile({
        type: CONNECTOR_TEMPLATE,
        file: payload,
        source: 'save-as',
        isFromTemplate: false
      });

      if (file) {
        const link = buildPath(`/connector-templates/${buildSlug(file.id, file.name)}`);
        notificationStore.showSuccess(<ConfigurationSavedNotification link={link} />);
      } else {
        throw new Error('File could not be saved.');
      }
    } catch (err) {
      console.error(err);
      notificationStore.showError("Yikes! Couldn't save your connector template. Please try again later.");
    } finally {
      closeDialog();
    }
  };

  const SaveAsButton = () => (
    <Tooltip
      showAfter={0}
      align="bottom"
      justify="right"
      title="Save the connector configuration as a new connector template."
    >
      <Styled.SaveAsButtonContainer onClick={openDialog}>
        <>
          Save as <ConnectorTemplate width="16" height="16" />
        </>
      </Styled.SaveAsButtonContainer>
    </Tooltip>
  );

  const SaveAsModal = () => (
    <Modal
      open={showDialog}
      onRequestClose={closeDialog}
      onRequestSubmit={saveAs}
      modalHeading="Save Connector configuration as template"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      size={dialogSize}
    >
      <p className="bx--modal-content__text">
        Reuse your connector configuration by saving it as a new connector template.
      </p>

      <Styled.InputContainer>
        <TextInput
          data-modal-primary-focus
          id="template-name"
          labelText="Template name"
          placeholder={defaultTemplateName}
          defaultValue={defaultTemplateName}
          onChange={(event) => {
            newTemplateName = event.target.value;
          }}
        />
      </Styled.InputContainer>

      <Styled.InputContainer>
        <TextArea
          id="template-description"
          labelText="Template description"
          enableCounter
          maxCount={100}
          placeholder={defaultTemplateDescription}
          defaultValue={defaultTemplateDescription}
          onChange={(event) => {
            newTemplateDescription = event.target.value;
          }}
        />
      </Styled.InputContainer>
    </Modal>
  );

  return (
    <>
      <SaveAsButton />
      <SaveAsModal />
    </>
  );
};

SaveConnectorConfig.propTypes = {
  onActivate: PropTypes.func
};

SaveConnectorConfig.defaultProps = {
  onActivate: () => {}
};

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

export default observer(SaveConnectorConfig);
