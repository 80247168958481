/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { InlineNotification, AccordionItem } from '@carbon/react';
import { observer } from 'mobx-react';

import { clustersStore } from 'stores';
import { connectorService } from 'services';
import { detailsPanelTabsStore, inboundConnectorStore } from 'App/Pages/Diagram/stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import capitalize from 'utils/capitalize';

import { InboundConnectorDetailContainer } from './';
import * as Styled from './InboundConnector.styled';

let pollingInterval;

const InboundConnector = () => {
  const { isInboundConnectorTabSelected } = detailsPanelTabsStore;
  const {
    parentProcessId,
    inboundConnectorId,
    selectedElementId,
    isSelectedElementInboundConnector,
    recentlyDeployed,
    needsRedeploymentElementIds,
    selectedInboundFunctionality,
    initialStatusDataLoadingInProgress,
    statusData
  } = inboundConnectorStore;
  const { latestDeployedCluster } = deploymentStore;

  const isBrowserTabVisible = useBrowserTabVisibilityListener();
  const { activeInboundConnectorLogs, initialLogLoadingInProgress } = usePollInboundConnectorLogs({
    parentProcessId,
    inboundConnectorId,
    selectedElementId,
    isBrowserTabVisible,
    isInboundConnectorTabSelected,
    isSelectedElementInboundConnector,
    needsRedeploymentElementIds,
    interval: 3000
  });

  const needsRedeployment = needsRedeploymentElementIds.has(selectedElementId);
  const filteredStatusData = statusData?.filter((data) => data?.elementId == selectedElementId);

  return (
    <Styled.InboundConnector>
      <>
        {initialStatusDataLoadingInProgress ? (
          <Styled.Loading description={`Updating ${selectedInboundFunctionality} status`} />
        ) : (
          <>
            {!needsRedeployment && (filteredStatusData?.length > 0 || recentlyDeployed) ? (
              <>
                <Styled.ConnectorDeployedMessage>
                  {`${capitalize(selectedInboundFunctionality)} deployed to the following clusters:`}
                </Styled.ConnectorDeployedMessage>
                <div>
                  <Styled.InboundAccordion isFlush>
                    {latestDeployedCluster &&
                      filteredStatusData &&
                      !filteredStatusData.some((statusData) => statusData.clusterId === latestDeployedCluster.uuid) && (
                        <AccordionItem
                          title={
                            <div>
                              {getStatusTitle(
                                { status: 'UPDATING', clusterName: latestDeployedCluster.name },
                                selectedInboundFunctionality
                              )}
                            </div>
                          }
                          key={latestDeployedCluster.uuid}
                        >
                          <></>
                        </AccordionItem>
                      )}
                    {filteredStatusData?.map((data) => (
                      <AccordionItem
                        title={<div>{getStatusTitle(data, selectedInboundFunctionality)}</div>}
                        key={data?.clusterId}
                      >
                        <InboundConnectorDetailContainer
                          inboundData={data}
                          inboundLogs={activeInboundConnectorLogs?.filter(
                            (log) => log?.clusterId && data?.clusterId && log?.clusterId === data?.clusterId
                          )}
                          initialLogLoadingInProgress={initialLogLoadingInProgress}
                        />
                      </AccordionItem>
                    ))}
                  </Styled.InboundAccordion>
                </div>
              </>
            ) : (
              <>
                <InlineNotification kind="info" lowContrast hideCloseButton>
                  <strong>{`${capitalize(selectedInboundFunctionality)} is not active in any cluster`}</strong>
                  {`Deploy the diagram to activate the ${selectedInboundFunctionality}`}
                </InlineNotification>
              </>
            )}
          </>
        )}
      </>
    </Styled.InboundConnector>
  );
};

const useBrowserTabVisibilityListener = () => {
  const [isBrowserTabVisible, setIsBrowserTabVisible] = useState(true);
  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        setIsBrowserTabVisible(false);
      } else {
        setIsBrowserTabVisible(true);
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);

  return isBrowserTabVisible;
};

/**
 * Poll if the browser tab is visible AND inbound connector tab is active
 */
const usePollInboundConnectorLogs = ({
  parentProcessId,
  inboundConnectorId,
  selectedElementId,
  needsRedeploymentElementIds,
  isBrowserTabVisible,
  isInboundConnectorTabSelected,
  isSelectedElementInboundConnector,
  interval = 5000
}) => {
  const [initialLoadingInProgress, setInitialLoadingInProgress] = useState(true);
  const [activeInboundConnectorLogs, setActiveInboundConnectorLogs] = useState(null);

  useEffect(() => {
    (async () => {
      const needsRedeployment = needsRedeploymentElementIds.has(selectedElementId);
      const canDoPolling =
        !needsRedeployment && isBrowserTabVisible && isInboundConnectorTabSelected && isSelectedElementInboundConnector;

      if (needsRedeployment) {
        setActiveInboundConnectorLogs(null);
      }

      if (canDoPolling) {
        const queryInboundConnectorStatus = async () => {
          const activeInboundConnectorLogArr = [];

          await Promise.all(
            clustersStore.clusters?.map(async (cluster) => {
              const baseUrl = cluster.urls.connectors;
              const activeInboundConnectorLogsResp = await connectorService.getActiveInboundConnectorLogs(
                baseUrl,
                '<default>',
                parentProcessId,
                selectedElementId
              );
              const extendedActiveInboundConnectorLogs = activeInboundConnectorLogsResp
                ? activeInboundConnectorLogsResp
                    .flat()
                    ?.map((inboundConnectorLogs) => ({
                      ...inboundConnectorLogs,
                      clusterId: cluster.uuid,
                      clusterName: cluster.name,
                      clusterUrl: baseUrl
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp)
                : {};
              activeInboundConnectorLogArr.push(extendedActiveInboundConnectorLogs);
            })
          );

          setActiveInboundConnectorLogs(activeInboundConnectorLogArr.flat());
        };

        // Make initial request
        await queryInboundConnectorStatus();
        setInitialLoadingInProgress(false);

        if (pollingInterval) {
          // Clear previous interval
          clearInterval(pollingInterval);
        }

        // Keep polling
        pollingInterval = setInterval(queryInboundConnectorStatus, interval);
      } else {
        clearInterval(pollingInterval);
      }
    })();

    return () => {
      clearInterval(pollingInterval);
    };
  }, [
    isBrowserTabVisible,
    isInboundConnectorTabSelected,
    inboundConnectorId,
    needsRedeploymentElementIds,
    parentProcessId
  ]);

  return { activeInboundConnectorLogs, initialLoadingInProgress };
};

const getStatusTitle = (data, selectedInboundFunctionality) => {
  let status = data?.health ? data?.health?.status : data?.status;
  return (
    <>
      <span>{getStatusIcon(status)}</span>
      <span>{getStatusText(data, selectedInboundFunctionality)}</span>
    </>
  );
};

const getStatusText = (data, selectedInboundFunctionality) => {
  let status = data?.health ? data?.health?.status : data?.status;
  if (status === 'UP') {
    return `${capitalize(selectedInboundFunctionality)} is active in ${data?.clusterName}`;
  } else if (status === 'UNKNOWN') {
    return `${capitalize(selectedInboundFunctionality)} status is unknown in ${data?.clusterName}`;
  } else if (status === 'DOWN') {
    return `${capitalize(selectedInboundFunctionality)} is inactive in ${data?.clusterName}`;
  } else if (status === 'UPDATING') {
    return `${capitalize(selectedInboundFunctionality)} is updating in ${data?.clusterName}`;
  } else {
    return `${capitalize(selectedInboundFunctionality)} status is unknown in ${data?.clusterName}`;
  }
};

const getStatusIcon = (status) => {
  if (status === 'UP') {
    return <Styled.StatusIconGreen />;
  } else if (status === 'UNKNOWN') {
    return <Styled.StatusIconYellow />;
  } else if (status === 'DOWN') {
    return <Styled.StatusIconRed />;
  } else if (status === 'UPDATING') {
    return <Styled.StatusIconLoading description={''} />;
  } else {
    return <Styled.StatusIconYellow />;
  }
};

export default observer(InboundConnector);
