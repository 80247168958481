/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect, useRef } from 'react';

import { Search, Cross } from 'icons';

import * as Styled from './SearchForm.styled';

export default function SearchForm({ onSearch }) {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [search, setSearch] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    if (isSearchVisible) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    onSearch(search);
  }, [search]);

  const handleSearchClick = () => {
    setIsSearchVisible(!isSearchVisible);

    if (isSearchVisible) {
      setSearch('');
    }
  };

  const handleChange = (evt) => {
    setSearch(evt.target.value);
  };

  return (
    <Styled.SearchForm>
      <Styled.SearchButton
        type="button"
        onClick={handleSearchClick}
        title="Search"
        aria-label="Search through this list"
        data-test="expand-entity-search"
      >
        {isSearchVisible ? <Cross width="16" height="16" /> : <Search />}
      </Styled.SearchButton>

      <Styled.SearchInput
        type="text"
        placeholder="type to search..."
        ref={inputRef}
        aria-hidden={!isSearchVisible}
        tabIndex={!isSearchVisible ? -1 : 0}
        $isVisible={isSearchVisible}
        onChange={handleChange}
        data-test="entity-search-input"
        value={search}
      />
    </Styled.SearchForm>
  );
}
