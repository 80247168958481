/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { BPMN, DMN, FORM, CONNECTOR_TEMPLATE } from 'utils/constants';

export const actions = {
  MODIFY_PROJECT: 'MODIFY_PROJECT',
  VIEW_PROJECT: 'VIEW_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  LEAVE_PROJECT: 'LEAVE_PROJECT',
  VIEW_PROJECT_DROPDOWN: 'VIEW_PROJECT_DROPDOWN',
  VIEW_COLLABORATORS: 'VIEW_COLLABORATORS',
  MODIFY_COLLABORATORS: 'MODIFY_COLLABORATORS',
  CREATE_DIAGRAM: 'CREATE_DIAGRAM',
  DELETE_DIAGRAM: 'DELETE_DIAGRAM',
  MODIFY_DIAGRAM: 'MODIFY_DIAGRAM',
  SHARE_DIAGRAM: 'SHARE_DIAGRAM',
  DELETE_MILESTONE: 'DELETE_MILESTONE',
  VIEW_MILESTONES: 'VIEW_MILESTONES',
  VIEW_VERSIONS: 'VIEW_VERSIONS',
  MODIFY_FORM: 'MODIFY_FORM',
  VIEW_FORM_PROPERTIES: 'VIEW_FORM_PROPERTIES',
  MODIFY_CONNECTOR_TEMPLATE: 'MODIFY_CONNECTOR_TEMPLATE',
  VIEW_PROPERTIES: 'VIEW_PROPERTIES',
  VIEW_INBOUND_CONNECTOR_TAB: 'VIEW_INBOUND_CONNECTOR_TAB',
  VIEW_PUBLICATION_TAB: 'VIEW_PUBLICATION_TAB'
};

export const types = {
  BPMN: BPMN,
  DMN: DMN,
  FORM: FORM,
  CONNECTOR_TEMPLATE: CONNECTOR_TEMPLATE
};

export const permissions = {
  ADMIN: 'ADMIN',
  WRITE: 'WRITE',
  READ: 'READ',
  COMMENT: 'COMMENT',
  NONE: 'NONE'
};
