/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { notificationStore } from 'stores';
import { trackingService } from 'services';

import saveFile from './save-file';

/**
 * Converts a given BPMN diagram into an SVG image and downloads
 * it to the user's computer.
 *
 * @param {Object} modeler An instance of the bpmn-js Modeler with access to the `saveSVG` method.
 * @param {Object} diagram
 * @param {String} [diagram.name] The diagram's name.
 * @param {String} [diagram.type] The diagram's type, like `BPMN`.
 */
export default async function exportDiagramSVG(modeler, diagram) {
  try {
    const fileName = `${diagram.name}.svg`;
    const { svg } = await modeler.saveSVG();

    saveFile(svg, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: diagram.id,
      fileTypeKey: diagram.type,
      exportType: 'svg',
      from: 'diagram',
      fileCount: 1,
      fileSize: svg.length
    });
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
  }
}
