/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default class DocumentationOnlyPropertiesProvider {
  constructor(elementTemplates, propertiesPanel, injector) {
    /**
     * 50 - Low priority to execute this last
     * Otherwise another plugin will add an additional property after the filtering is done in this one
     */
    propertiesPanel.registerProvider(50, this);

    this._elementTemplates = elementTemplates;
    this._injector = injector;
  }

  getGroups() {
    return (groups) => {
      groups = groups.filter((group) => group.id === 'documentation');
      return groups;
    };
  }
}

DocumentationOnlyPropertiesProvider.$inject = ['elementTemplates', 'propertiesPanel', 'injector'];
