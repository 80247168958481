/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Container = styled.div`
  // 40 is the height offset for the list header
  height: calc(100% - 40px);
`;

export const ErrorPanelItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  line-height: 18px;

  text-transform: capitalize;

  svg {
    fill: ${({ type }) =>
      type === 'success'
        ? 'var(--cds-support-success)'
        : type === 'error'
          ? 'var(--cds-support-error)'
          : 'var(--cds-support-warning)'};
  }
`;
