/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { ElementMenuButton } from 'primitives';

import * as Styled from './CommentsOverlay.styled';

const CommentsOverlay = ({ bpmnjs, element }) => {
  const eventBus = bpmnjs.get('eventBus');

  const onClick = useCallback(
    (event) => {
      eventBus.fire('contextPad.showComments', {
        element,
        originalEvent: event
      });
    },
    [element, eventBus]
  );

  return (
    <ElementMenuButton onClick={onClick} data-test="bpmn-overlay" title="Open comments tab">
      <Styled.CommentIcon />
    </ElementMenuButton>
  );
};

export default observer(CommentsOverlay);
