/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import buildPath from 'utils/build-path';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  min-height: 550px;
  background-repeat: no-repeat;
  background-image: url(${buildPath('/img/sleeping-cow.svg')});

  @media (min-width: 900px) {
    background-size: 400px;
    background-position: right center;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 900px) {
    background-size: 380px;
    background-position: center 30px;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
  }
`;

export const Heading = styled.h1`
  font-weight: 500;
  margin-bottom: 0;
  color: var(--grey-darken-23);

  @media (min-width: 900px) {
    max-width: 50%;
  }
`;

export const Message = styled.p`
  margin-top: 0;

  @media (min-width: 900px) {
    max-width: 50%;
  }
`;

export const Details = styled.details`
  margin-top: 2rem;
  position: relative;
  width: 50%;
`;

export const Code = styled.code`
  position: absolute;
  left: 0;
  background-color: white;
  padding: var(--spacing-tiny);
  border: 1px solid var(--silver-darken-87);

  @media (max-width: 900px) {
    width: 100%;
  }
`;
