/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';
import { useExperiment } from 'statsig-react';

import { trackingService } from 'services';

export default function useExperimentWithTracking({ experimentName, tracking = true }) {
  const experiment = useExperiment(experimentName);

  useEffect(() => {
    if (tracking && experiment?.config?.value?.group) {
      trackingService.trackExperiment({
        experimentKey: experimentName,
        group: experiment?.config?.value?.group
      });
    }
  }, [tracking]);

  return experiment;
}
