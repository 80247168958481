/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { userStore } from 'stores';
import { useQuery } from 'hooks';
import { authService } from 'services';

export default function useAuthentication() {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    switch (location.pathname) {
      case '/login':
        if (userStore.isAuthenticated) {
          history.push(query.get('returnUrl') || '/');
        } else {
          authService.loginWithRedirect();
        }
        break;
      case '/login-callback':
        authService.handleRedirectCallback();
        break;
      case '/logout':
        if (userStore.isSuperAdminModeActive) {
          userStore.toggleSuperUserMode(); // Turn off
        }
        authService.logout();
        break;
    }
  }, [location]);
}
