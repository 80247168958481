/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function deleteItem(items, toBeRemoved) {
  const isUsedInShare = items.length > 0 && typeof items[0] === 'string';

  if (isUsedInShare) {
    // Array of strings (emails)
    items.remove(toBeRemoved);
  } else {
    // Array of objects
    const indexToRemove = items.findIndex((item) => item.id === toBeRemoved);
    if (indexToRemove !== -1) {
      items.splice(indexToRemove, 1);
    }
  }
}
