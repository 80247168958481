/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { findExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export function getCallActivitiesWithLinkedProcess(modeler) {
  const elementRegistry = modeler.get('elementRegistry');

  const callActivities = elementRegistry.filter((element) => is(element, 'bpmn:CallActivity'));

  return callActivities.filter((element) => {
    const calledElement = findExtensionElement(element, 'zeebe:CalledElement');

    if (!calledElement) {
      return false;
    }

    const processId = calledElement.get('processId');

    return typeof processId === 'string' && processId.trim().length > 0;
  });
}
