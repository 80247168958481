/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Tag as _Tag } from '@carbon/react';

export const Tag = styled(_Tag)`
  border: 0;

  // Hide default browser tooltip
  span {
    pointer-events: none;
    cursor: default;
  }
  span::before {
    content: none !important;
  }
`;
