/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const DiagramContainer = styled.div`
  display: none;
`;

export const PanelContainer = styled.div`
  width: 100%;
  height: calc(100% - 28px); // 28px from the Header
  border-left: 1px solid var(--silver-darken-87);

  // hide the "Applied" button in the properties panel to prevent users from unlinking the template
  .bio-properties-panel-group[data-group-id='group-ElementTemplates__Template'] .bio-properties-panel-dropdown-button {
    display: none;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: white;
  border-bottom: 1px solid var(--silver-darken-87);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  padding-left: var(--spacing-small);
`;
