/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';

/**
 * For users with only permission to view properties, the design mode is not shown
 * Automatically switch to validate mode (if design was selected by default)
 */
export default function useSwitchToAvailableModeForUserWithPermissionToOnlyReadProperties(
  hasPermissionToOnlyViewProperties,
  isFormPlaygroundReady,
  mode,
  switchMode
) {
  useEffect(() => {
    if (hasPermissionToOnlyViewProperties && isFormPlaygroundReady) {
      if (mode === 'design' || mode === 'validate') {
        switchMode('validate', 'system');
      }
    }
  }, [isFormPlaygroundReady]);
}
