/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Link } from '@carbon/react';
import { DataEnrichment } from '@carbon/react/icons';

import buildPath from 'utils/build-path';
import { trackingService } from 'services';
import URL from 'components/TopBar/url';

import * as Styled from './ConnectToDiagramGuidance.styled';

const ConnectToDiagramGuidance = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        size="sm"
        renderIcon={DataEnrichment}
        onClick={() => {
          setOpen(true);
          trackingService.trackResourceLinking({ action: 'link', resource: 'form', from: 'form' });
        }}
      >
        Connect to diagram
      </Button>

      {ReactDOM.createPortal(
        <Styled.ConnectToDiagramModal
          className="connect-to-diagram-modal"
          open={open}
          size="sm"
          modalHeading="Connect the form to a BPMN diagram"
          primaryButtonText="Got it"
          onRequestClose={() => setOpen(false)}
          onRequestSubmit={() => setOpen(false)}
        >
          <p>
            To use the form, connect it to a User Task event on a BPMN diagram. Read more about it in the{' '}
            <Link href={URL.FORM_LINKING} target="_blank" rel="noreferrer">
              documentation
            </Link>
            .
          </p>
          <Styled.ConnectFormGuidance>
            <img src={buildPath('/img/form-linking-guidance.jpg')} alt="Connect form to element" width="100%" />
          </Styled.ConnectFormGuidance>
        </Styled.ConnectToDiagramModal>,
        document.body
      )}
    </>
  );
};

export default ConnectToDiagramGuidance;
