/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PublicationBehavior from './PublicationBehavior';
import PublicationTabExtension from './PublicationTabExtension';

export default {
  __init__: ['publicationBehavior', 'publicationTabExtension'],
  publicationBehavior: ['type', PublicationBehavior],
  publicationTabExtension: ['type', PublicationTabExtension]
};
