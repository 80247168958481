/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { currentDiagramStore } from 'stores';
import { addFirstOverlayFromRight, addSecondOverlayFromRight } from 'App/Pages/Diagram/overlay-util';
import createPermission from 'utils/createPermission';

import { getBusinessRuleTasksWithLinkedDecision } from './utils';
import businessRuleTaskLinkStore from './BusinessRuleTaskLinkStore';

const OVERLAY_TYPE = 'business-rule-task-menu';

export function BusinessRuleTaskLinkExtension(bpmnjs, elementRegistry, eventBus, overlays) {
  const addOverlays = () => {
    elementRegistry.forEach((element) => addOverlayIfSupported(element));
  };

  const addOverlayIfSupported = (element) => {
    if (is(element, 'bpmn:BusinessRuleTask')) {
      const permission = createPermission(currentDiagramStore.state?.project?.permissionAccess);

      if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
        // TODO(philippfromme): this is a hack to make sure overlays don't overlap
        addSecondOverlayFromRight(overlays, OVERLAY_TYPE, element);
      } else {
        addFirstOverlayFromRight(overlays, OVERLAY_TYPE, element);
      }
    }
  };

  const setBusinessRuleTasksWithLinkedDecision = () => {
    const businessRuleTasks = getBusinessRuleTasksWithLinkedDecision(bpmnjs);

    businessRuleTaskLinkStore.setLinkedBusinessRuleTasks(businessRuleTasks);
  };

  const setSelectedElementIfSupported = (element) => {
    if (element && is(element, 'bpmn:BusinessRuleTask')) {
      businessRuleTaskLinkStore.setSelectedBusinessRuleTask(element);
    } else {
      businessRuleTaskLinkStore.setSelectedBusinessRuleTask(null);
    }
  };

  eventBus.on('import.done', () => {
    setBusinessRuleTasksWithLinkedDecision();

    addOverlays();
  });

  eventBus.on('shape.added', (context) => {
    const { element } = context;

    addOverlayIfSupported(element);
  });

  eventBus.on('elements.changed', () => {
    setBusinessRuleTasksWithLinkedDecision();
  });

  eventBus.on('selection.changed', (event) => {
    const { newSelection = [] } = event;

    const element = newSelection.length === 1 && newSelection[0];

    setSelectedElementIfSupported(element);
  });
}

BusinessRuleTaskLinkExtension.$inject = ['bpmnjs', 'elementRegistry', 'eventBus', 'overlays'];

export default {
  __init__: ['businessRuleTaskLinkExtension'],
  businessRuleTaskLinkExtension: ['type', BusinessRuleTaskLinkExtension]
};
