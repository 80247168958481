/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './VersionSelector.styled';

export default function Selector({ executionPlatformVersion, setAnchorEl, selectionRef, isReadOnly = false }) {
  return (
    <Styled.SelectionWithLabel
      onClick={(e) => {
        e.preventDefault();
        if (!isReadOnly) {
          setAnchorEl(selectionRef.current);
        }
      }}
      data-test="version-selector"
      $isReadOnly={isReadOnly}
    >
      <Styled.SelectionLabel $isReadOnly={isReadOnly}>Check problems against:</Styled.SelectionLabel>
      <Styled.Selection ref={selectionRef} $isReadOnly={isReadOnly}>
        Zeebe {executionPlatformVersion.substring(0, 3)}
      </Styled.Selection>
    </Styled.SelectionWithLabel>
  );
}
