/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button, Modal } from '@carbon/react';
import { Add } from '@carbon/icons-react';
import { observer } from 'mobx-react';

import { currentDiagramStore, organizationStore } from 'stores';
import config from 'utils/config';
import { CloudProcess } from 'icons';
import { trackingService } from 'services';

import { clusterCreationModalStore } from './';
import { Content, InnerContent, Message, Title, Visual, Wrapper } from './ClusterCreationModal.styled';
import PlayModeInfo from './PlayModeInfo';

const ClusterCreationModal = ({ onClose = () => {}, onSubmit = () => {} }) => {
  const { isVisible, close } = clusterCreationModalStore;
  const { diagram } = currentDiagramStore.state;
  const onRequestClose = () => {
    close();
    onClose();
  };

  const onCreateNewCluster = () => {
    trackingService.trackClusterCreation({ diagram });
    window.open(
      `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`
    );
    onSubmit();
  };

  return (
    <Wrapper>
      <Modal open={isVisible} onRequestClose={() => onRequestClose()} passiveModal>
        <Content>
          <Visual>
            <CloudProcess />
          </Visual>

          <InnerContent>
            <Title>Processes run on clusters</Title>

            <Message>
              Click <strong>Create new cluster</strong> to set up your environment to deploy your process
            </Message>

            <Button
              data-modal-primary-focus
              renderIcon={Add}
              onClick={() => onCreateNewCluster()}
              size="md"
              iconDescription="Create new cluster"
            >
              Create new cluster
            </Button>

            <PlayModeInfo />
          </InnerContent>
        </Content>
      </Modal>
    </Wrapper>
  );
};

export default observer(ClusterCreationModal);
