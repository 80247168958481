/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { TopBar, Breadcrumb, Dropdown } from 'components';
import { breadcrumbStore, projectStore, userStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { getPageTitle } from 'utils/helpers';
import currentUserIsACollaborator from 'utils/currentUserIsACollaborator';

const ProjectHeader = () => {
  const history = useHistory();
  const { project, collaborators, loading } = projectStore;
  const admins = collaborators?.filter((collaborator) => collaborator.permissionAccess === 'ADMIN');
  const canLeaveProject =
    (project.permissionAccess === 'ADMIN' ? admins.length > 1 : true) && currentUserIsACollaborator(collaborators);
  const pageTitle = loading ? 'Loading...' : project.name || 'Project Not Found';

  const handleEnterProjectRename = () => {
    breadcrumbStore.toggleDropdownVisibility();
    breadcrumbStore.toggleEditingFor('project');
  };

  const handleProjectDelete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    if (await projectStore.deleteProject()) {
      history.push('/');
    }
  };

  const handleProjectLeave = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    if (await projectStore.leaveProject({ email: userStore.userEmail, iamId: userStore.userAuthProviderId })) {
      history.push('/');
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" data-test="breadcrumb-home" />

        {hasAccess(project, actions.VIEW_PROJECT_DROPDOWN) && project.name ? (
          <Breadcrumb
            title={project.name}
            variant="dropdown"
            isBold
            forPage="project"
            data-test="breadcrumb-project-menu"
            handleSubmit={(name) => {
              breadcrumbStore.finishEditing();
              projectStore.renameProject(name);
            }}
          >
            {hasAccess(project, actions.MODIFY_PROJECT) && (
              <Dropdown.ListItem key="edit" onClick={handleEnterProjectRename} data-test="rename-project">
                Edit name
              </Dropdown.ListItem>
            )}
            {hasAccess(project, actions.DELETE_PROJECT) && (
              <Dropdown.ListItem key="delete" onClick={handleProjectDelete} data-test="delete-project">
                Delete
              </Dropdown.ListItem>
            )}
            {hasAccess(project, actions.LEAVE_PROJECT) && canLeaveProject && (
              <Dropdown.ListItem key="leave-project" onClick={handleProjectLeave} data-test="leave-project">
                Leave project
              </Dropdown.ListItem>
            )}
          </Breadcrumb>
        ) : (
          <Breadcrumb title={pageTitle} variant="text" isBold data-test="breadcrumb-project" />
        )}
      </TopBar.Breadcrumbs>
    </Fragment>
  );
};

export default observer(ProjectHeader);
