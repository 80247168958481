/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import { InlineNotification } from '@carbon/react';

import { Breadcrumb, EntityTable, TopBar, ImportModal } from 'components';
import { LinkRenderer } from 'components/EntityTable/CellRenderer';
import { trackingService } from 'services';
import { homeStore, organizationStore, userStore } from 'stores';
import { Button, EmptyState } from 'primitives';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';
import config from 'utils/config';

import { Header } from './Header';
import { ListTitle } from './ListTitle';
import ActionButton from './ActionButton';
import EntityAction from './EntityAction';
import getBatchActions from './BatchActions';
import { getCollaborators, getItemsCount } from './utils';
import * as Styled from './Home.styled';

export const Home = () => {
  const { orgId } = useParams();
  const { projects, loading, isCreatingProject } = homeStore;
  const { isAuthenticated, isSuperAdminModeActive } = userStore;
  const location = useLocation();
  const [resourcesToImport, setResourcesToImport] = useState([]);

  useEffect(() => {
    homeStore.reset();

    if (isAuthenticated && orgId) {
      organizationStore.setOrganization(orgId);
    }

    trackingService.trackPageView('home');
    homeStore.init();

    return () => {
      homeStore.reset();
    };
  }, [orgId, isSuperAdminModeActive]);

  /**
   * If the user is on the import route, fetch the resources metadata and set the state.
   */
  useEffect(() => {
    if (!config.marketplace?.enabled) {
      return;
    }

    (async () => {
      const isImportRoute = location.pathname && location.search && location.pathname.includes('import');

      if (isImportRoute) {
        const resources = new URLSearchParams(location.search).get('source').split(',');
        setResourcesToImport(resources);
      }
    })();
  }, [location.pathname, location.search, organizationStore]);

  const hasResourcesToImport = !!resourcesToImport.length;

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle('Home')}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" isBold to="/" />
      </TopBar.Breadcrumbs>

      <Header />

      {isSuperAdminModeActive && (
        <Styled.SuperUserModeWarning>
          <InlineNotification
            className="inline-notification-warning"
            kind="warning"
            lowContrast
            hideCloseButton
            title="Super-user mode is activated:"
            subtitle={`As the organization ${userStore.isOrgAdmin ? 'admin' : 'owner'}, you have full access to all organization files. Please be mindful when making changes, as they may be irreversible.`}
          />
        </Styled.SuperUserModeWarning>
      )}

      {config.marketplace?.enabled && (
        <ImportModal open={hasResourcesToImport} resourcesToImportURLs={resourcesToImport} projects={projects} />
      )}

      <Styled.Container>
        <EntityTable
          action={({ tabIndex }) => projects.length > 0 && <ActionButton tabIndex={tabIndex} />}
          batchActions={getBatchActions()}
          emptyState={
            <EmptyState
              icon={<Styled.EmptyStateIcon />}
              title="Start by creating a project"
              description="Click Create new project to organize and share your diagrams."
              action={
                <Button
                  data-test="create-project"
                  onClick={() => homeStore.createProject()}
                  loading={isCreatingProject}
                  loaderSize="small"
                  lightLoader
                >
                  Create new project
                </Button>
              }
              link={
                <a href="https://docs.camunda.io/docs/guides/" target="_blank" rel="noreferrer">
                  View the getting started guide
                </a>
              }
            />
          }
          columns={[
            { key: 'name', header: 'Name', renderer: LinkRenderer, sortable: true },
            { key: 'content', header: 'Content', width: '120px', sortable: true },
            { key: 'lastChanged', header: 'Last changed', width: '200px', sortable: true },
            {
              key: 'collaborators',
              header: 'Collaborators',
              width: '200px',
              renderer: (AvatarList) => {
                return <Styled.CollaboratorsWrapper>{AvatarList}</Styled.CollaboratorsWrapper>;
              }
            },
            {
              key: 'entityAction',
              header: '',
              width: '50px',
              noPadding: true,
              renderer: (project) => <EntityAction project={project} />
            }
          ]}
          isLoading={loading}
          title={<ListTitle />}
          rows={projects.map((project) => ({
            id: project.id,
            content: getItemsCount(project),
            collaborators: getCollaborators(project),
            name: {
              value: project.name,
              link: `/projects/${buildSlug(project.id, project.name)}`
            },
            lastChanged: moment(project.lastModified).format('YYYY-MM-DD HH:mm'),
            entityAction: project
          }))}
        />
      </Styled.Container>
    </Fragment>
  );
};

export default observer(Home);
