/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useExperimentWithTracking } from 'App/Statsig';
import config from 'utils/config';

const EXPERIMENT_NAME = 'modeler_contextpad';

export default function useNewContextPadExperiment({ tracking = true } = {}) {
  const experiment = useExperimentWithTracking({
    experimentName: EXPERIMENT_NAME,
    tracking
  });

  return config?.statsig?.enabled && experiment?.config?.get('enable_feature', false);
}
