/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, makeObservable, observable } from 'mobx';

class FeedbackDialogStore {
  state = {
    isOpen: false,
    message: null,
    type: null
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      close: action,
      open: action
    });
  }

  close = () => {
    this.state.isOpen = false;
    this.state.message = null;
    this.state.type = null;
  };

  open = ({ type, message }) => {
    this.state.isOpen = true;
    this.state.message = message;
    this.state.type = type;
  };

  setType = (type) => {
    this.state.type = type;
  };

  reset = () => {
    this.close();
  };
}

export default new FeedbackDialogStore();
