/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, makeObservable, observable } from 'mobx';
import BPMNModdle from 'bpmn-moddle';

import { convertConnectorId } from './utils';

export class DetectConnectorsStore {
  connectors = [];
  connectorElements = [];

  constructor() {
    makeObservable(this, {
      connectors: observable,
      connectorElements: observable,
      setConnectors: action,
      setConnectorElements: action
    });
  }

  setConnectors = (arr) => {
    this.connectors = arr;
  };

  setConnectorElements = (arr) => {
    this.connectorElements = arr;
  };

  detectConnectors = async (xml) => {
    const connectors = [];
    try {
      if (xml) {
        const bpmnModdle = new BPMNModdle();
        const { elementsById } = await bpmnModdle.fromXML(xml);
        Object.values(elementsById)?.forEach((el) => {
          if (el?.$attrs?.hasOwnProperty('zeebe:modelerTemplate')) {
            const connector = convertConnectorId(el?.$attrs['zeebe:modelerTemplate']);
            if (connector && !connectors.includes(connector)) {
              connectors.push(connector);
            }
          }
        });
      }
    } catch (e) {
      console.info(e);
    }
    return connectors;
  };
}

export default new DetectConnectorsStore();
