/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Link = styled.span`
  margin-top: 16px;
  color: var(--cds-blue-60);
  cursor: pointer;
`;
