/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { ActionableNotification as CarbonToastNotification } from '@carbon/react';
import styled from 'styled-components';

export const transitionDuration = 350;
const offset = 352;

export const ToastNotification = styled(CarbonToastNotification)`
  position: relative;
  right: -${offset}px;
  z-index: var(--z-notification);

  // ActionableNotification is smaller than a Toast Notification, but we want to keep the same width
  min-width: 352px;

  transition:
    transform ${transitionDuration}ms ease-in-out,
    opacity ${transitionDuration / 2}ms ease-in-out;

  opacity: 0;

  &.entered {
    transform: translateX(-100%);
    opacity: 1;
  }

  &.exiting,
  &.exited {
    transform: translateX(100%);
    opacity: 0;
  }
`;
