/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { commentsStore, currentDiagramStore } from 'stores';
import { ErrorIcon, Errors as ErrorsStyled } from 'components';

import DocumentationLink from './DocumentationLink';

export default function Errors({ errors, emptyMessage }) {
  const selectElementAndProperty = (error) => {
    try {
      commentsStore.makePropertiesVisible();
      currentDiagramStore.modeler.get('linting').showError(error);
    } catch (err) {
      console.error('Error while selecting element and property', err);
    }
  };

  return errors?.length > 0 ? (
    <ErrorsStyled.Errors>
      {errors.map((error, i) => (
        <ErrorsStyled.Error
          // eslint-disable-next-line react/no-array-index-key
          key={`${error.id}_${i}`}
          data-test={`error-${error.id}`}
          onClick={() => selectElementAndProperty(error)}
        >
          <ErrorsStyled.Element title={error.id} data-test="errorElement">
            <ErrorIcon category={error.category} />
            <ErrorsStyled.ElementId>{error.name || error.id}</ErrorsStyled.ElementId>
          </ErrorsStyled.Element>
          <ErrorsStyled.Message title={error.message}>
            {error.message}
            {error?.documentation?.url && (
              <>
                {' '}
                <DocumentationLink href={error?.documentation?.url} />
              </>
            )}
          </ErrorsStyled.Message>
        </ErrorsStyled.Error>
      ))}
    </ErrorsStyled.Errors>
  ) : (
    <ErrorsStyled.EmptyState>
      <ErrorsStyled.SuccessIcon />
      <ErrorsStyled.EmptyStateMessage>{emptyMessage}</ErrorsStyled.EmptyStateMessage>
    </ErrorsStyled.EmptyState>
  );
}
