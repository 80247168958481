/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { CodeSnippet as CodeSnippetComponent } from '@carbon/react';
import styled from 'styled-components';

export const CodeSnippet = styled(CodeSnippetComponent)`
  background-color: white;
  margin-bottom: 10px;

  .cds--tooltip-trigger__wrapper button {
    background: white;
  }
`;
